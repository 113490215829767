import React, {useEffect, useState, useRef} from 'react'
import moment from "moment";
import BusIcon from "../../components/SvgIcons/BusIcon";
import LocationMarker from "../../components/SvgIcons/LocationMarker";
import ClockIcon from "../../components/SvgIcons/ClockIcon";

const TicketViewRouteWidget = ({stations}) => {

    if (!stations) {
        return null;
    }

    const {0: first, [stations.length - 1]: last} = stations;

    const departDt = moment.utc(first.depart_at).local()
    const arrivalDt = moment.utc(last.arrival_at).local()

    const duration = moment.duration(arrivalDt.diff(departDt))
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes())%60;

    return (
        <div className="route-widget">
            <div className="time-start time">
                <div className="stamp">{departDt.format('HH:mm')}</div>
                <div className="date">{departDt.format('D MMM, ddd')}</div>
            </div>

            <div className="infographics--cell">
                <div className="icon-wrap">
                    <BusIcon/>
                </div>

                <div className="dashed-time">
                    <div className="time-info">
                        <ClockIcon/> {hours} ч {minutes} мин
                    </div>
                </div>

                <div className="icon-wrap">
                    <LocationMarker/>
                </div>
            </div>

            <div className="time-end time">
                <div className="stamp">{arrivalDt.format('HH:mm')}</div>
                <div className="date">{arrivalDt.format('D MMM, ddd')}</div>
            </div>
        </div>
    )
}
export default TicketViewRouteWidget
