import React, {useEffect} from 'react';
import {ValidatorForm} from "react-material-ui-form-validator";

export const  ValidatorsContainer= (Component) => {

     function wrapValidatorsContainer(props){
         useEffect(()=>{
              //list custom validations здесь свои валидаторы
             ValidatorForm.addValidationRule('isTruthy', value => { return value});
             ValidatorForm.addValidationRule('minPhone', value => {
                 if(value && value.length>8){
                     return  true
                 }else{
                     return  false
                 }
             });
         },[])

      return <Component
        { ...props }
      />

  }
    return wrapValidatorsContainer;
}
