import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Select from '../../../elements/Select';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import api from '../../../api/baseAPI';
import ListOrders from '../../../components/ListOrders/ListOrders';

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return  (
        <div  className={ clsx('filterOption',data.type) } ref={ innerRef } { ...innerProps }>
            {data.label}
        </div>
    )
};

const ArrowDropDownIcon = () => {
    return <svg className='arrow-drop-down-icon' width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.15521 8L0.862091 0.499999L13.4483 0.5L7.15521 8Z" fill="#E97D35"/>
    </svg>
}

const MyOrders = (props) => {

     const [ data,setData ]=useState([]);
    useEffect(()=>{
      api.post('/auth/user-account/orders').then(data=>{
          setData(data.data.collection)
      })
    },[ props.isAuth ])

    return (
        <div className='user-account-tab--content_item fade-div'>
            <h1 className="user-account-tab--content_item">Мои заказы</h1>

            <div className='contact-data-form'>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                    <div className="contact-data-form--row flex-row">
                        <div className="contact-data-form--row__cell">
                            <button className="base-orange-btn write-orange set_up">Создать новый заказ</button>
                        </div>

                        <div className="contact-data-form--row__cell">
                            <Select
                                    key={ 1 }
                                    defaultValue={ {} }
                                    variant="standard"
                                    components={ { Option: CustomOption,
                                        DropdownIndicator: () => <ArrowDropDownIcon />,
                                        IndicatorSeparator: () => null }
                                    }
                                    onInputChange={ f=>f }
                                    onChange={ f=>f }
                                    options={ [ {}, {}, {} ] }
                                    isLoading={ false }
                                    placeholder={ '' }
                                    // indicatorsContainer={ false }
                                />
                        </div>

                        <div className="contact-data-form--row__cell">
                            <Select
                                    key={ 2 }
                                    defaultValue={ {} }
                                    components={ { Option: CustomOption,
                                        DropdownIndicator: () => <ArrowDropDownIcon />,
                                        IndicatorSeparator: () => null } }
                                    onInputChange={ f=>f }
                                    onChange={ f=>f }
                                    options={ [ {}, {}, {} ] }
                                    isLoading={ false }
                                    placeholder={ '' }
                                    indicatorsContainer={ false }
                                />
                        </div>
                    </div>
                </Box>
            </div>

            <ListOrders data={ data } />

        </div>
    )
}
// const mapDispatchToProps = {
//     removeUser,
// }
const mapStateToProps = (state) => {

    return {
        isAuth: state.auth.isAuth,
        user: state.auth.user,
    };
}
export default connect(mapStateToProps, null)(withTranslation()(MyOrders));
