import React, { forwardRef, useEffect, useImperativeHandle,useState } from 'react';
import Select from '../../../elements/Select';
import clsx from 'clsx';
import useInputDebounce from '../../../hooks/useInputDebounce';
import { getData } from '../../../api/baseAPI';
import {  useToasts } from 'react-toast-notifications'
import ToastInfo from '../../../elements/ToastInfo';
import { getStore } from '../../../app';
import PropTypes from 'prop-types';
import useRequest from '../../../hooks/useRequest';
import { getStationByAlias } from '../../../api/listAPI';

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
        return  (
            <div  className={ clsx('filterOption',data.type) } ref={ innerRef } { ...innerProps }>
                {data.label}
            </div>
        )
};
// ref only FUNCTIONS!! useImperativeHandle
const FilterSelect=forwardRef((props,ref)=>{
    const [ searchText, setSearchText ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ renderKey, setRenderKey ] = useState(null);
    const { addToast } = useToasts()
    const debouncedSearchText = useInputDebounce(searchText, 800);
    const [ dataBaseLoad, loadingBaseLoad, reUpdateBaseLoad ] = useRequest(getStationByAlias.bind(null, props.slug))

    useEffect(()=>{
        if(props.slug){
            reUpdateBaseLoad()
        }
    },[])

    useEffect(()=>{
        // debugger
        if(dataBaseLoad){
            const prepareArray=[];
            setIsLoading(true)
            dataBaseLoad.collection.forEach(element=>{
                prepareArray.push({ id:element.id,label:element.label,slug:element.slug,type:element.type })
                element.stations.forEach(element=>{
                    prepareArray.push(element)
                })
            })
            props.onSearch(prepareArray);
            setIsLoading(false)
            if(prepareArray.find(e=>e.slug==props.slug)){
                props.onSelect(prepareArray.find(e=>e.slug==props.slug))
            }
            // props.onSelect(prepareArray.find(e=>e.slug==props.slug))

        }
    },[ dataBaseLoad ])

    useEffect(()=>{
        if(searchText.length>2){
            setIsLoading(true)
            getData('/cities',{ name:searchText }).then(data => {
                const prepareArray=[];
                data.data.collection.forEach(element=>{
                    prepareArray.push({ id:element.id,label:element.label,slug:element.slug,type:element.type })
                     element.stations.forEach(element=>{
                         prepareArray.push(element)
                     })
                })
                    props.onSearch(prepareArray);
                    setIsLoading(false)
            })
        }else{
            setIsLoading(false)
        }
    },[ debouncedSearchText ])

//force render select
    useEffect(()=>{
        setRenderKey(Math.random())
    },[ props.currentValue ])

    useImperativeHandle(ref, () => ({
        runSearch: (searchText,indexSearch) => {
            setSearchText(searchText);
            setTimeout(()=>{
                const locState= getStore().getState()
                let  stFilter=[];
                if(props.debug=='from'){
                   stFilter=locState.filter.filterFrom
                }else if (props.debug=='to'){
                    stFilter=locState.filter.filterTo
                }
                if(stFilter.length>=indexSearch){
                    props.onSelect(stFilter[ indexSearch ]);
                    const e=stFilter[ indexSearch ];
                    addToast( <ToastInfo textArray={ [ `id: ${ e.id }`,`from: ${ e.label }`, ] }/>,{ appearance: 'info', autoDismiss: true })
                }},3000)
        }
    }));

    return (
        <React.Fragment>
            <Select
                key={ renderKey }
                defaultValue={ props.currentValue }
                // defaultValue={colourOptions[0]}
                // ref={ref => {
                //     setInputRef(ref);
                // }}
                components={ { Option: CustomOption, DropdownIndicator: () => null, IndicatorSeparator: () => null } }
                onInputChange={ setSearchText }
                onChange={ props.onSelect }
                // onChange={(a,b,c)=>{
                //     console.log('dddddd',a,b,c)
                //     props.onSelect(a)
                // }}
                options={ props.data }
                isLoading={ isLoading }
                placeholder={ props.placeholder }
                indicatorsContainer={ false }
                // loadingMessage={()=>{return 'Some me'}}
                // cacheOptions={true}
                // isClearable={false}
                // loadOptions={loadOptions}
                // defaultOptions={true}
            />
        </React.Fragment>
    );
})
//warning  Warning: forwardRef render functions do not support propTypes or defaultProps. Did you accidentally pass a React component?
FilterSelect.displayName = 'FilterSelect';
FilterSelect.propTypes = {
 placeholder:PropTypes.string.isRequired,
 onSearch:PropTypes.func.isRequired,
 onSelect:PropTypes.func.isRequired,
 data:PropTypes.array.isRequired,
 slug:PropTypes.string.isRequired,
 currentValue:PropTypes.object,
 debug:PropTypes.string, //from to
};
export default FilterSelect;
// export default forwardRef(FilterSelect);
