import React, {Fragment} from 'react'
import moment from "moment";

function RoutePrimaryInfo({obj}) {

    const trip = obj.trip

    const departDt = moment.utc(obj.stations_between[0].depart_at).local()

    return (
        <Fragment>
            <div className="route-primary-info">
                <span className='route-primary-info__label'>Рейс</span>
                {/*<span className='route-primary-info__text'*/}
                {/*>№{trip.number} {trip.stations[0].name} - {trip.stations[trip.stations.length - 1].name},</span>*/}

                <span className='route-primary-info__text'>№{trip.number} {trip.route.name},</span>
                <span className='route-primary-info__label'>по маршруту</span>
                <span
                    className='route-primary-info__text'>{trip.stations[0].name} — {trip.stations[trip.stations.length - 1].name},</span>
                <span className='route-primary-info__label'>на</span>
                <span className='route-primary-info__text'>{departDt.format('D MMMM')}</span>
                <span className='route-primary-info__label'>{departDt.format('YYYY')} года в</span>
                <span className='route-primary-info__text'>{departDt.format('HH:mm')}</span>
            </div>

            <div className="route-type">Тип рейса: {trip.has_schedule ? 'Регулярный' : 'Не Регулярный*'}</div>
        </Fragment>
    )
}

export default RoutePrimaryInfo
