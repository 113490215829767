import React from 'react';
import PropTypes from 'prop-types';
import TopMenu from "../components/TopMenu";
import MainBG from "../components/MainBG";
import MainContent from "../components/MainContent";
import Questions from "../components/Questions";
import Rules from "../components/Rules";
import Footer from "../components/Footer";

const Home = props => {
    return (
        <React.Fragment>
            <TopMenu/>

            <MainBG/>

            <MainContent/>

            <Questions/>

            <Rules/>

            <Footer/>
        </React.Fragment>
    );
};

Home.propTypes = {

};

export default Home;
