import Listener from './listener'

export default class Bell {

  static listeners = []

  static hit = (eventName, data) => {
    Bell.listeners.forEach(listener =>
      listener.eventName === eventName && listener.handle(data)
    )
    return Bell
  }

  static off = (handler) => {
    if (handler) {
      Bell.listeners = Bell.listeners.filter(listener =>
        listener.handler !== handler
      )
    }
    return Bell
  }

  static on = (name, handler) => {
    if (name && handler) {
      const listener = new Listener(name, handler);
      Bell.listeners.push(listener)
    }
    return Bell
  }

}