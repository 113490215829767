import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import TopMenu from '../components/TopMenu'
import Filter from '../components/filter/Filter'
import TicketItem from './components/TicketItem';
// import {setFilterDataFrom} from '../redux/reducers/filterReducer';
import {connect} from 'react-redux';

const SearchResults = (props) => {

    const {t, i18n, ready} = useTranslation();

    function stationBetween() {
        if (props.data && props.data.length && props.data[0].stations_between && props.data[0].stations_between.length) {
            return `${props.data[0].stations_between[0].name} - ${props.data[0].stations_between[props.data[0].stations_between.length - 1].name}`
        }

        return ''
    }

    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className="page-container">
                <div className="page-form-filter--container">
                    <Filter/>
                </div>
                <div className="page-container__desc">{t('bus_tickets')}</div>
                <h2 className="page-container__title">{stationBetween()}</h2>
                {props.data ?
                    <div className='search-results--list'>
                        {props.data.trips_straight && props.data.trips_straight.map((obj, i) =>
                            <TicketItem key={i} obj={obj}/>
                        )}

                        {props.data.trips_back && props.data.trips_back.map((obj, i) =>
                            <TicketItem key={i} obj={obj}/>
                        )}
                    </div>
                    : 'Loader ...'}
            </div>
        </div>
    )
}

SearchResults.propTypes = {}
// const mapDispatchToProps = {
//     setFilterDataFrom,
// }
const mapStateToProps = (state) => {
    // debugger
    return {
        data: state.routeSearch.data,
    };
}
export default connect(mapStateToProps, null)(SearchResults);
