import { FILTER_DATA } from '../ActionTypes';
export const demoOption = [
    { id: 1, label: 'Львов',type:'area' },
    { id: 2, label: 'Львов ас1' ,type:'place' },
    { id: 3, label: 'Львов ас2' ,type:'place' },
    { id: 4, label: 'Львов ас3' ,type:'place' },

    { id: 1, label: 'Дубно',type:'area' },
    { id: 2, label: 'Дубно ас1' ,type:'place' },
    { id: 3, label: 'Дубно ас2' ,type:'place' },
    { id: 4, label: 'Дубно ас3' ,type:'place' },
];
const initialSetting = {

    filterFrom:[],
    // filterFrom:demoOption,
    filterTo:[],

    selectedFrom:{},
    selectedTo:{},

    selectedDateFrom:null,
    selectedDateTo:null,

    data:[],

};
const filterReducer = (state = initialSetting, action) => {
    switch (action.type) {
      case FILTER_DATA.SET_FROM:
        return { ...state,filterFrom: action.payload }
      case FILTER_DATA.SET_TO:
        return { ...state,filterTo: action.payload }
        case FILTER_DATA.SET_SELECTED_FROM:
            return { ...state,selectedFrom: action.payload }
        case FILTER_DATA.SET_SELECTED_TO:
            return { ...state,selectedTo: action.payload }

        case FILTER_DATA.SET_SELECTED_DATE_FROM:
            return { ...state,selectedDateFrom: action.payload }
        case FILTER_DATA.SET_SELECTED_DATE_TO:
            return { ...state,selectedDateTo: action.payload }

      case FILTER_DATA.SET:
        return { ...state,data: action.payload }
        default:
            return state;
    }
}
export default filterReducer;

export const setFilterDataFrom=(data)=> ({ type:FILTER_DATA.SET_FROM,payload: data });
export const setFilterDataTo=(data)=> ({ type:FILTER_DATA.SET_TO,payload: data });

//check  a select
export const setFilterDataSelectedFrom=(data)=> ({ type:FILTER_DATA.SET_SELECTED_FROM,payload: data });
export const setFilterDataSelectedTo=(data)=> ({ type:FILTER_DATA.SET_SELECTED_TO,payload: data });

//выбор времени
export const setFilterDateFrom=(data)=> ({ type:FILTER_DATA.SET_SELECTED_DATE_FROM,payload: data });
export const setFilterDateTo=(data)=> ({ type:FILTER_DATA.SET_SELECTED_DATE_TO,payload: data });

export const setFilterDataPlay=(data)=> ({ type:FILTER_DATA.SET,payload: data });
