import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next) // if not using I18nextProvider
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false
        }
    });

export default i18n;
