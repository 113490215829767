import React, {useEffect, useState, useRef} from 'react'
import {useTranslation} from "react-i18next";

const ToggleDetails = ({action}) => {
    const [toggle, setToggle] = useState(false)
    const {t} = useTranslation();
    const onToggleAction = () => {
        setToggle(!toggle)
        action()
    }

    return (
        <div className="details-toggle--row">
            <button onClick={onToggleAction}
                    className={toggle ? "toggle-button open-details" : "toggle-button"}>{toggle ? <span className='fade'>{t('hide_details')}</span> :
                <span className='fade'>{t('show_details')}</span>}
                <i className="arrow"><svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.67074 0.135102L0.129616 5.67623C-0.0475487 5.85967 -0.0424621 6.15198 0.140979 6.32915C0.31993 6.50198 0.603615 6.50198 0.782538 6.32915L5.9972 1.11448L11.2119 6.32915C11.3922 6.50942 11.6845 6.50942 11.8648 6.32915C12.0451 6.14882 12.0451 5.85653 11.8648 5.67623L6.32366 0.135102C6.14333 -0.045174 5.85104 -0.0451741 5.67074 0.135102Z"
                            fill="#4D8EF5"/>
                    </svg>
                </i>
            </button>
        </div>
    )
}
export default ToggleDetails
