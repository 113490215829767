import React from 'react';
import PropTypes from 'prop-types';

const Form = props => {
    return (
            <form {...props} >
                {props.children}
            </form>
    );
};

Form.propTypes = {};

export default Form;
