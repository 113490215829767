import React, {useEffect, useState, useRef} from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const AccountSetup = () => {
    return(
        <div className='user-account-tab--content_item fade-div'>
            <h1 className="user-account-tab--content_item">Настройка учетной записи</h1>

            <div className='contact-data-form'>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                    <div className="contact-data-form--row flex-row">
                        <div className="contact-data-form--row__cell">
                            <div className="contact-data-form--label">Новый пароль</div>

                            <TextField fullWidth placeholder={'Новый пароль'}/>
                        </div>

                        <div className="contact-data-form--row__cell">
                            <div className="contact-data-form--label">Повторите пароль</div>

                            <TextField fullWidth placeholder={'Повторите пароль'}/>
                        </div>

                        <div className="contact-data-form--row__cell"></div>
                    </div>

                    <button className='button-link orange'>Сохранить</button>
                </Box>
                <br/>

                <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                    <div className="subscription-setup">
                        <h3>Настройка подписки</h3>

                        <p>Я хочу получить информацию о спецпредложениях, акциях и дополнительных услугах по email/телефону/viber</p>

                        <p>Вы можете в любой момент изменить настройки подписки. Подробная информация о политике конфиденциальности и условиях пользования персональных данных, в том числе о праве отозвать согласие доступна <a
                            href="">по ссылке.</a></p>

                        <div className="subscription-status">Статус: <span>{`вы отписались от подписки`}</span></div>

                        <RadioGroup
                            defaultValue="subscribe"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="subscribe" control={<Radio />} label="Подписаться" />
                            <FormControlLabel value="unsubscribe" control={<Radio />} label="Отписаться" />
                        </RadioGroup>
                        <br/>
                        <button className='button-link orange'>Сохранить</button>
                    </div>
                </Box>
            </div>
        </div>
    )
}
export default AccountSetup
