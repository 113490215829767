import React, {useEffect, useState} from 'react'

function LeftTime({time: ptime, onExpired}) {

    const [time, setTime] = useState(ptime)

    useEffect(() => {
        let t = ptime

        const interval = setInterval(() => {
            if (t <= 0) {
                clearInterval(interval)
                onExpired && onExpired()
            } else {
                setTime(--t)
            }
        }, 1000)

        return clearInterval.bind(null, interval)
    }, [])

    function toMinSec(time) {
        return `${Math.floor(time / 60)}:${("0" + (time % 60)).slice(-2)}`
    }

    return time ? toMinSec(time) : null
}

export default LeftTime
