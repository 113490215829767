import React, {useEffect, useState, useRef} from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from "react-i18next";

const Options = ({name, list, value, onChange}) => {

    const { t, i18n, ready } = useTranslation();

    if (!list) {
        return null
    }

    return (
        <div className='custom-drop-down'>
            <Box
                component="form"
                noValidate
                autoComplete="off">
                <TextField
                    select
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}>
                    {list.map((option, i) => (
                        <MenuItem key={i} value={String(i)}>
                            {t(option)}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </div>
    )
}
export default Options
