import { AUTH } from '../ActionTypes';

const initialSetting = {
     user:{},
     isAuth:false,
     access_token:null,
};
const authReducer = (state = initialSetting, action) => {
    switch (action.type) {
      case AUTH.SET_USER:
        return { ...state,user: action.payload }
      case AUTH.SET_IS_AUTH:
          return { ...state,isAuth: action.payload }
      case AUTH.SET_ACCESS_TOKEN:
            return { ...state,access_token: action.payload }
      case AUTH.REMOVE_USER:
          localStorage.removeItem('token')
        return { ...state,...initialSetting }
        default:
            return state;
    }
}
export default authReducer;
export const setUser=(data)=> ({ type:AUTH.SET_USER,payload: data });
export const setIsAuth=(data)=> ({ type:AUTH.SET_IS_AUTH,payload: data });
export const setAccessToken=(data)=> ({ type:AUTH.SET_ACCESS_TOKEN,payload: data });
export const removeUser=()=> ({ type:AUTH.REMOVE_USER });
