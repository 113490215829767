import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import useFormField from '../../hooks/useFormField';
import { useNavigate } from 'react-router-dom';
import { isMinLengthString, isNotValidEmail } from '../../utils/responceFormValidatorHelper';
import { storeContainer } from '../../api/storeContainer';
import AwaitDialog from '../../components/Dialogs/AwaitDialod/AwaitDialog';
import RegisterSuccess from "../../components/Dialogs/RegisterSuccess/RegisterSuccess";

const SignUp = (props) => {
    const navigate = useNavigate();
    const [ loading,setLoading ]=useState(false)
    const [ showSuccessModal,setShowSuccessModal ]=useState(false)
    // const name = useFormField('Bob')
    // const lastName = useFormField('Marley')
    // const phone = useFormField('+8096568596546')
    // const email = useFormField(Math.random()+'we@gmail.com')
    // const password = useFormField('Marley')
    // const passwordConfirmation = useFormField('Marley')

    const name = useFormField('')
    const lastName = useFormField('')
    // const phone = useFormField('')
    const [ phone,setPhone ] = useState('')
    const email = useFormField('')
    const password = useFormField('')
    const passwordConfirmation = useFormField('')

    const [ errorName,setErrorName ] = useState(false);
    const [ errorLastName,setLastName ] = useState(false);
    const [ errorPhone,setErrorPhone ] = useState(false);
    const [ errorPassword,setErrorPassword ] = useState(false);
    const [ errorPasswordConfirm,setErrorPasswordConfirm ] = useState(false);
    const [ errorEmail,setErrorEmail ] = useState(false);

    const validate=()=>{
        let result =true;
        //name
        if(isMinLengthString(name.value,2)){
            setErrorName(isMinLengthString(name.value,2));
            result= false;
        }else{
            setErrorName(false)
        }

        //lastname
        if(isMinLengthString(lastName.value,2)){
            setLastName(isMinLengthString(lastName.value,2));
            result= false;
        }else{
            setLastName(false)
        }
        //phone
        if(isMinLengthString(phone,2)){

            setErrorPhone(isMinLengthString(phone,2));
            result= false;
        }else{
            setErrorPhone(false)
        }
        // //password
        // if(isMinLengthString(password.value,6)){
        //     setErrorPhone(isMinLengthString(password,6));
        //     result= false;
        // }else{
        //     setErrorPhone(false)
        // }

        if(isNotValidEmail(email.value)){
            setErrorEmail(isNotValidEmail(email.value));
            result= false;
        }else{
            setErrorEmail(false)
        }
        //password
        if(isMinLengthString(password.value,6)){
            setErrorPassword(isMinLengthString(password.value,6));
            result= false;
        }else{
            setErrorPassword(false)
        }
        //password confirm
        if(isMinLengthString(passwordConfirmation.value,6)){
            setErrorPasswordConfirm(isMinLengthString(passwordConfirmation.value,6));
            result= false;
        }else{
            setErrorPasswordConfirm(false)
        }

        return result;
    }
    return (
        <React.Fragment>
            <div className='fade sign_up'>
                <div className='contact-data-form '>
                    <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                        <div className="contact-data-form--row flex-row">
                            <div className="contact-data-form--row__cell">
                                <TextField fullWidth placeholder={ 'Фамилия*' }{ ...lastName }  error={ errorLastName } helperText={ errorLastName } />
                            </div>
                            <div className="contact-data-form--row__cell">
                                <TextField fullWidth placeholder={ 'Имя*' } { ...name } error={ errorName } helperText={ errorName }/>
                            </div>
                        </div>
                        <div className="contact-data-form--row flex-row">
                            <div className="contact-data-form--row__cell">
                                {/*<InputMask mask="+38 (___) ___ ___ ___"  value={phone} onChange={(e,r,t)=>{*/}
                                {/*   setPhone(e.target.value);*/}
                                {/*}} >*/}
                                {/*    {(inputProps) => <TextField fullWidth  {...inputProps} value={phone} type="tel" placeholder={ 'Телефон*' } />}*/}
                                {/*</InputMask>*/}
                                {/*<TextField fullWidth  { ...phone } type="tel" placeholder={ 'Телефон*' } error={ errorPhone } helperText={ errorPhone } />*/}

                                <PhoneInput country={ 'ee' } value={ phone } onChange={ setPhone }  error={ errorPhone } helperText={ errorPhone }

                                            // error={ errorPhone } helperText={ errorPhone }
                                />
                                {errorPhone && <span className={ 'react-tel-input-error' }>{errorPhone}</span>}
                            </div>

                            <div className="contact-data-form--row__cell">
                                <TextField fullWidth placeholder={ 'Email*' } { ...email }  error={ errorEmail } helperText={ errorEmail }/>
                            </div>
                        </div>

                        <div className="contact-data-form--row flex-row">
                            <div className="contact-data-form--row__cell">
                                <TextField fullWidth placeholder={ 'Пароль*' } { ...password } type={ 'password' } error={ errorPassword } helperText={ errorPassword }/>
                            </div>

                            <div className="contact-data-form--row__cell">
                                <TextField fullWidth placeholder={ 'Повторите пароль*' } { ...passwordConfirmation } type={ 'password' } error={ errorPasswordConfirm } helperText={ errorPasswordConfirm }/>
                            </div>
                        </div>

                        <div className="contact-data-form--row flex-row">
                            <div className="contact-data-form--row__cell">
                                {/*<ReCAPTCHA*/}
                                {/*    sitekey="Your client site key"*/}
                                {/*    onChange={ f=>f }*/}
                                {/*/>,*/}
                            </div>

                            <div className="contact-data-form--row__cell">
                                <button disabled={ loading } onClick={ event => {
                                    event.preventDefault();
                                   if( validate()){
                                       setLoading(true)
                                       props.register(name.value,email.value,password.value,passwordConfirmation.value,lastName.value,phone)
                                           .then(e=>{
                                               if(e){
                                                   setLoading(false)
                                                   setShowSuccessModal(true)
                                               }
                                               setLoading(false)
                                           })
                                   }

                            } } className="base-orange-btn">Зарегистрироваться</button>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
            <AwaitDialog open={ loading } onClose={ f=>f }/>
            <RegisterSuccess  open={ showSuccessModal }  onClose={ ()=>{
                setShowSuccessModal(false)
                navigate('/')

            } } />
        </React.Fragment>
    )
}
export default storeContainer(SignUp)
