import React from 'react';
import {Link} from "react-router-dom";

const Logo = () => {
    return <div className='site-logo'>
        <Link className='navbar-brand' to='#'>
            <img src={'/images/logo.svg'} alt='Superpilet'/>
        </Link>
    </div>
}
export default Logo
