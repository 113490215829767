import React, { useEffect, } from 'react';
import Form from '../../../elements/Form';

import FilterSelectCalendarFrom from './FilterSelectCalendarFrom';
import Button from '../../../elements/Button';
import { connect } from 'react-redux';
import useRequest from '../../../hooks/useRequest';
import { getListBySelect, getListRoutes, getStationByAlias } from '../../../api/listAPI';
import { setRouteData } from '../../../redux/reducers/routeSearchReducer';
import useKeyPress from '../../../hooks/useKeyPress';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FilterSelect from './FilterSelect';
import {
    setFilterDataFrom,
    setFilterDataSelectedFrom,
    setFilterDataSelectedTo,
    setFilterDataTo, setFilterDateFrom, setFilterDateTo
} from '../../../redux/reducers/filterReducer';

const FilterForm = props => {
    const { t, i18n, ready } = useTranslation();
    const [ data, loading, reUpdate,  ] = useRequest(getListRoutes.bind(null, props.selectedFrom.slug,
        props.selectedTo.slug,props.selectedDateFrom,props.selectedDateTo))
    const routeParams = useParams();
    const navigate = useNavigate();
    // only testing todo rm on live
    const fromPoint = React.createRef();
    const toPoint = React.createRef();
    const [ pressedKey ] = useKeyPress();
    useEffect(() => {
        if (data) {
            props.setRouteData(data)
        }
    }, [ data ])
    useEffect(() => {
            getListRoutes(routeParams.from,routeParams.to, routeParams.dt, routeParams.dtBack).then(responce=>{
                props.setRouteData(responce.data)
            })
          if(routeParams.dt){
                props.setFilterDateFrom(routeParams.dt)
          }
          if(routeParams.dtBack){
               props.setFilterDateTo(routeParams.dtBack)
          }
    }, [])

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {

            const indexSearch = 0;//1...   0 - это город  1 автостанция  уточнить у Александра
            if (pressedKey === '1') {
                console.log(pressedKey)
                // debugger

                fromPoint.current.runSearch('Tempo', indexSearch)
                toPoint.current.runSearch('Aseri', indexSearch)
            }
            if (pressedKey === '2') {
                console.log(pressedKey)
                fromPoint.current.runSearch('New Marcosburgh', indexSearch)
                toPoint.current.runSearch('Smitham Trail', indexSearch)


                // fromPoint.current.runSearch('Льв', 0)
                // toPoint.current.runSearch('Хар', 0)
                // fromPoint.current.runSearch('Одесс',indexSearch)
                // toPoint.current.runSearch('Хар',indexSearch)
            }
        }

    }, [pressedKey])

    return (
        <React.Fragment>
            <Form className={'flex-form primary-filter-form'} onSubmit={(e) => {
                e.preventDefault();
                reUpdate()
                if (1) {
                    if (props.selectedFrom.slug && props.selectedTo.slug) {
                       const navPath= '/'.concat( ['search-results',props.selectedFrom.slug,props.selectedTo.slug,
                           props.selectedDateFrom,props.selectedDateTo ].filter(e=>e).join('/'))
                        navigate(navPath);
                    }
                }
            }}>
                {/*//from*/}
                <div className="formBlock from from-to">
                    <FromToButton />

                    <FilterSelect
                                  ref={ fromPoint }
                                  placeholder={ t('placeholder_from') }
                                  onSelect={ props.setFilterDataSelectedFrom }
                                  onSearch={  props.setFilterDataFrom }
                                  data={ props.filterFrom }
                                  slug={ routeParams.from }
                                  debug={ 'from' }
                                  currentValue={ props.selectedFrom }/>
                </div>
                {/*//to*/}
                <div className="formBlock to">
                    <FilterSelect ref={ toPoint }
                                  placeholder={ t('placeholder_to')}
                                  onSelect={ props.setFilterDataSelectedTo }
                                  onSearch={  props.setFilterDataTo }
                                  data={ props.filterTo }
                                  slug={ routeParams.to }
                                  debug={ 'to' }
                                  currentValue={ props.selectedTo }
                    />
                </div>

                <FilterSelectCalendarFrom/>

                <div className="formBlock small">
                    <Button variant="contained" type={'submit'}> {t('btn_search')} </Button>
                </div>
            </Form>
        </React.Fragment>
    );
};

const FromToButton = () => {
    return (
        <button className='from-to-btn'>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.209091 5.35205L5.20918 0.351953C5.48818 0.0730422 5.94045 0.073126 6.21936 0.35212C6.35325 0.486051 6.42846 0.667611 6.4285 0.856997V2.99989H11.4286C11.8231 2.99989 12.1429 3.31969 12.1429 3.7142C12.1429 4.10871 11.8231 4.42851 11.4286 4.42851H5.71419C5.31968 4.42851 4.99988 4.10871 4.99988 3.7142V2.58127L1.7241 5.85705L4.99988 9.13283V7.99994C4.99988 7.60543 5.31968 7.28563 5.71419 7.28563H13.5715V5.14274C13.5716 4.74823 13.8915 4.42851 14.2859 4.4286C14.4753 4.42864 14.6569 4.50385 14.7908 4.63774L19.7908 9.63783C20.0697 9.91678 20.0697 10.3689 19.7908 10.6478L14.7908 15.6479C14.6568 15.7819 14.4752 15.8572 14.2858 15.8572C14.1919 15.8575 14.0988 15.8391 14.0122 15.803C13.7453 15.6923 13.5714 15.4318 13.5715 15.1429V13H8.57139C8.17688 13 7.85708 12.6802 7.85708 12.2857C7.85708 11.8912 8.17688 11.5714 8.57139 11.5714H14.2858C14.6803 11.5714 15.0001 11.8912 15.0001 12.2857V13.4186L18.2758 10.1428L15.0001 6.86705V7.99994C15.0001 8.39445 14.6803 8.71425 14.2858 8.71425H6.4285V10.8571C6.42841 11.2517 6.10853 11.5714 5.71406 11.5713C5.52472 11.5712 5.34312 11.496 5.20918 11.3621L0.209091 6.36205C-0.0697362 6.0831 -0.0697362 5.63096 0.209091 5.35205Z" fill="#E97D35"/>
            </svg>
        </button>
    )
}

FilterForm.propTypes = {};

const mapDispatchToProps = {
    // setFilterDataFrom,
    setRouteData,

    //for from
    setFilterDataFrom,
    setFilterDataSelectedFrom,

    //for to
    setFilterDataTo,
    setFilterDataSelectedTo,
     //set date
    setFilterDateFrom,
    setFilterDateTo,

}
const mapStateToProps = (state) => {

    return {
        filterFrom: state.filter.filterFrom,
        filterTo: state.filter.filterTo,
        selectedFrom: state.filter.selectedFrom,
        selectedTo: state.filter.selectedTo,
        selectedDateFrom: state.filter.selectedDateFrom,
        selectedDateTo: state.filter.selectedDateTo,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
