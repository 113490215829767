import React from 'react';
export const urlApi = (path = '/') => {
    return  path;
    // return process.env.API_URI.concat(ltrimSlashes(path))
}
const APPCONFIG = {
  api_uri: urlApi(),
  nodeEnv:process.env.NODE_ENV,
  stripeApiKey: process.env.STRIPE_APIKEY,
};
export default APPCONFIG;
