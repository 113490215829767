export default class Listener {

  constructor(eventName, handler) {
    this.eventName = eventName
    this.handler = handler
  }

  handle(data) {
    this.handler(data)
  }

}
