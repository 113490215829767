import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import AuthService from './api/AuthService';
// import { firstEngineError } from './utils/responceFormValidatorHelper';
import { removeUser, setAccessToken, setIsAuth, setUser } from './redux/reducers/authReducer';

export const  appContainer= (Component) => {
    function wrapAppContainer(props) {
        const { addToast } = useToasts()
        async  function getUserProfile(){
            try {
                const response= await AuthService.userProfile()
                props.setUser(response.data)
                props.setIsAuth(true)
            }catch (e){
                props.removeUser();
                // if(firstEngineError(e.response.data)){
                //     addToast(firstEngineError(e.response.data), { appearance: 'error', autoDismiss: true });
                // }
                // console.log(firstEngineError(e.response.data))
            }
        }



        useEffect(()=>{
            if(localStorage.getItem('token')){
                // const response= AuthService.userRefresh()
                props.setAccessToken(localStorage.getItem('token'))
            }
        },[])
        useEffect(()=>{
               //todo переделать по refresh
               if (props.access_token){
                   getUserProfile().then(f=>f)
               }
        },[ props.access_token ])


        return <Component
            { ...props }
        />

    }

    const mapDispatchToProps = {
        setUser,
        setIsAuth,
        setAccessToken,
        removeUser
    }
    appContainer.propTypes = {}
    const mapStateToProps = (state) => {

        return {
            access_token: state.auth.access_token,
            isAuth: state.auth.isAuth,
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(wrapAppContainer);
}

// appContainer.propTypes = {}
// // const mapDispatchToProps = {
// //     setFilterDataFrom,
// // }
// const mapStateToProps = (state) => {
//     // debugger
//     return {
//         data: state.routeSearch.data,
//     };
// }
// export default connect(mapStateToProps, null)(appContainer);
