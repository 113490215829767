import React, {Component, useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import LangSelector from './LangSelector'
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {removeUser} from '../redux/reducers/authReducer';
import Cart from "./Cart";

function TopMenu(props) {
    const [menuActiveClass, setMenuActiveClass] = useState(false);
    const navigate = useNavigate();
    function toggleClassMenu() {
        setMenuActiveClass(!menuActiveClass)
    }
    const {t, tReady} = props

    useEffect(() => {
        if(menuActiveClass){
            document.getElementsByTagName('html')[0].classList.add('no-scroll')
        }else {
            document.getElementsByTagName('html')[0].classList.remove('no-scroll')
        }
        return () => {
            document.getElementsByTagName('html')[0].classList.remove('no-scroll')
        }
    }, [menuActiveClass])

    if (!tReady) {
        return null
    }

    return (
        <header className={'header-block'}>
            <div className={`container ${menuActiveClass ? 'active' : ''}`}>
                <div className="popup-backdrop" onClick={toggleClassMenu}/>
                <a className='navbar-brand' href='/'><img src={'/images/logo.svg'} alt='Superpilet'/></a>
                <button className='navbar-toggler navbar-toggler-icon' onClick={toggleClassMenu}/>
                <ul className='navbar-nav'>
                    <div className='mobile-header'>
                        <p>  {t('Menu')}</p>
                        <div onClick={toggleClassMenu}>
                            <img src={'/images/close.svg'} alt='Cross close image'/>
                        </div>
                    </div>

                    <li className='nav-item active'>
                        <a className='nav-link' href='/about'>{t('about_us')}</a>
                    </li>

                    <li className='nav-item'>
                        <Link to={'/support'} className='nav-link'>{t('support')}</Link>
                    </li>

                    {/*<li className='nav-item'>*/}
                    {/*    <a className='nav-link' href='/ticket-return'>{t('Refunds Ticket')}</a>*/}
                    {/*</li>*/}

                    {props.isAuth ? <React.Fragment>
                            <li className='nav-item'>
                                <Link className='nav-link' to={'/user-account'}>
                                    <img src={'/images/privat-cabinet.svg'} alt='Personal cabinet orange icon'/>
                                    {t('Account')}</Link>
                            </li>
                            {/*<li className='nav-item'>*/}
                            {/*    <a onClick={ (event => {*/}
                            {/*            event.preventDefault();*/}
                            {/*            props.removeUser();*/}
                            {/*            navigate('/search-results');*/}

                            {/*        }) } className='nav-link' href='#'>*/}
                            {/*        {t('logout')}</a>*/}
                            {/*</li>*/}
                        </React.Fragment>
                        : <li className='nav-item'>
                            <Link to={'/authorization'} className='nav-link'>
                                <img src={'/images/privat-cabinet.svg'} alt='Personal cabinet orange icon'/>
                                {t('login')}</Link>
                        </li>
                    }

                    <LangSelector/>

                    <li className='nav-item cart-item'>
                        {/*<Link to={'/cart'} className='nav-link'>{t('Cart')}</Link>*/}
                        <Cart/>
                    </li>
                </ul>
            </div>
        </header>
    )

}

const mapDispatchToProps = {
    removeUser,
    // // setFilterDataFrom,
    // setRouteData,
    //
    // //for from
    // setFilterDataFrom,
    // setFilterDataSelectedFrom,
    //
    // //for to
    // setFilterDataTo,
    // setFilterDataSelectedTo,
    // //set date
    // setFilterDateFrom,
    // setFilterDateTo,

}
const mapStateToProps = (state) => {

    return {
        isAuth: state.auth.isAuth,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopMenu));

// export default withTranslation()(TopMenu);
