import React from 'react'

const ClockIcon = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5624 11.7659L10.7738 9.67449V5.41426C10.7738 4.9859 10.4276 4.63965 9.99922 4.63965C9.57086 4.63965 9.22461 4.9859 9.22461 5.41426V10.0618C9.22461 10.3058 9.33926 10.5359 9.53445 10.6815L12.6328 13.0053C12.7722 13.1099 12.9349 13.1602 13.0968 13.1602C13.3331 13.1602 13.5654 13.0541 13.7173 12.8496C13.9745 12.508 13.9048 12.0223 13.5624 11.7659Z" fill="#98969B"/>
            <path d="M10 0C4.48566 0 0 4.48566 0 10C0 15.5143 4.48566 20 10 20C15.5143 20 20 15.5143 20 10C20 4.48566 15.5143 0 10 0ZM10 18.4508C5.34082 18.4508 1.54918 14.6592 1.54918 10C1.54918 5.34082 5.34082 1.54918 10 1.54918C14.66 1.54918 18.4508 5.34082 18.4508 10C18.4508 14.6592 14.6592 18.4508 10 18.4508Z" fill="#98969B"/>
        </svg>
    )
}
export default ClockIcon


