import React, {useEffect, useState} from 'react'
import Seat from './Seat'

const SeatsWidget = ({seats, seatsSelected, onSelect, takenPlaces}) => {

    const [selected, setSelected] = useState(seatsSelected || [])

    function handleSelectSeat(seat) {
        if (!seat.driver && !seat.taken) {
            let tmp = []
            if (selected.includes(seat)) {
                tmp = selected.filter(s => s !== seat)
            } else {
                selected.push(seat)
                tmp = [...selected]
                tmp.sort(function (a, b) {
                    return a.place - b.place;
                });
            }

            setSelected(tmp)
            onSelect && onSelect(tmp)
        }
    }

    function range(length) {
        return [...Array(length).keys()]
    }

    function max(array, key) {
        return Math.max.apply(Math, array.map(o => o[key]))
    }

    function getSeat(x, y) {
        return seats.find(seat => seat.x === x && seat.y === y)
    }

    const matrix = {
        x: max(seats, 'x') + 1,
        y: max(seats, 'y') + 1,
    }

    return (
        <div className='seats-widget-wrap' style={{'--base-row--count': `${matrix.x}`}}>
            {range(matrix.y).map((_, y) =>
                <div key={y} className='seats-row'>
                    {range(matrix.x).map((_, x) =>
                        <Seat
                            key={x}
                            seat={getSeat(x, y)}
                            onSelect={handleSelectSeat}
                            takenPlaces={takenPlaces}
                            selected={selected}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
export default SeatsWidget
