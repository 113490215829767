import React, {useState} from 'react'

const Rules = props => {

    return (
        <React.Fragment>
            <section className={'rules'}>
                <section className={'container '}>
                    <h3>Покоряйте мир вместе с Superpilet!</h3>
                    <div className={'rules-block'}>
                        <div className={'rules-wrapper'}>
                            <img className={'rules-icon'} src={'images/rules-icon-1.svg'}
                                 alt="Rules icon" width={'80'}
                                 height={'80'}/>
                            <p className={'rules-caption'}><strong>Соблюдение правил</strong></p>
                            <p className={'rules-text'}>Пассажирские перевозки возможны только при соблюдении правил
                                карантина</p>
                        </div>
                        <div className={'rules-wrapper'}>
                            <img className={'rules-icon'} src={'images/rules-icon-2.svg'}
                                 alt="Rules icon" width={'80'}
                                 height={'80'}/>
                            <p className={'rules-caption'}><strong>Санитарные нормы</strong></p>
                            <p className={'rules-text'}>Соблюдайте масочный режим, мойте руки и сохраняйте дистанцию</p>
                        </div>
                        <div className={'rules-wrapper'}>
                            <img className={'rules-icon'} src={'images/rules-icon-3.svg'}
                                 alt="Rules icon" width={'80'}
                                 height={'80'}/>
                            <p className={'rules-caption'}><strong>Безопасность</strong></p>
                            <p className={'rules-text'}>Покупайте билеты на автобус безопасно онлайн из дома</p>
                        </div>
                    </div>
                    <div className={'subscribe-link-block'}>
                        <p className={'subscribe-caption'}><strong>Мы предлагаем бесплатные советы о выгодных
                            путешествиях</strong></p>
                        <p className={'subscribe-text'}>Подпишитесь на рассылку, чтобы первым получать интересные
                            предложения, новости и скидки от Superpilet!</p>
                        <div className={'subscribe-form'}>
                            <input className={'subscribe-input'} type="email" placeholder="Email Address"/>
                            <button className='button-link orange'>Подписаться</button>
                        </div>
                    </div>
                </section>
            </section>
        </React.Fragment>
    );
};

Rules.propTypes = {};

export default Rules;
