import { ROUTE_SEARCH_DATA } from '../ActionTypes';
export const demoOption = [
    {
      name:'ЛЬвов - Харьков',
      transportName: 'Bus',
        schedules:[],

      // id: 1,
      // nameFrom: 'Львов',
      // streetFrom: 'Шевченка',
      // timeFrom: '20-21-2011',
      // typeTransport: 'Bus',
      // type:'area',
      //
      // nameTo: 'Киев',
      // streetTo: 'Каштановая',
      // timeTo: '20-21-2011',
      // typeStation: '---',
      // price: '25',
      // countTickets: '31',
      // meta:{},
    },

];
const initialSetting = {
    // data:demoOption,
    // data:{schedules:[],nameFrom:'',nameTo:''},
    data:[],
};
const routeSearchReducer = (state = initialSetting, action) => {
    switch (action.type) {
      case ROUTE_SEARCH_DATA.SET_DATA:
        return { ...state,data: action.payload }
        default:
            return state;
    }
}
export default routeSearchReducer;

export const setRouteData=(data)=> ({ type:ROUTE_SEARCH_DATA.SET_DATA,payload: data });
