import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import PhoneInput from 'react-phone-input-2';
const style = {

    fontSize: '12px',
    color: '#d20e0e',
    position: 'relative',
    marginTop: '-20px',
    paddingLeft:'48px'

};
class PhoneValidatorElement extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, value, ...rest } = this.props;
        return (
            <div>
                <PhoneInput
                    { ...rest }
                     value={this.props.value}
                     onChange={this.props.onChange}
                     // value={this.props}

                    ref={ (r) => { this.input = r; } }
                />
                {this.errorText()}
            </div>
        );
    }
    errorText() {
        const { isValid } = this.state;
        if (isValid) {
            return null;
        }
        return (
            <div style={ style }>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default PhoneValidatorElement;
