import api from "./baseAPI";


export default class  AuthService {
    static async register(name,email,password,password_confirmation,lastName,phone){
        return api.post('/auth/register',{ name,email,password,password_confirmation,lastName,phone })
    }
    static async login(email,password){
        return api.post('/auth/login',{ email,password })
    }
    static async userProfile(){
        return api.get('/auth/user-profile')
    }
    static async logout(){
        return api.post('/auth/logout')
    }
    static userRefresh(){
        return api.post('/auth/refresh')
    }
}
