import React, { useState, useEffect } from 'react';
import TopMenu from '../../components/TopMenu';
import Footer from '../../components/Footer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BreadCrumbs from '../../components/Ui/BreadCrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';

const ForgotPass = () => {
    const { t } = useTranslation();
    const breadcrumbsData= [ { text:t('home_page'),href:'/' },{ text:t('forgot_pass'),href:false } ]
    return(
        <div className='page page-content'>
            <TopMenu/>
            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={ breadcrumbsData } />
                </div>
            </div>

            <div className="page-container">
                <div className="authorization-page">
                    <div className="authorization-page--head">
                        {t('forgot_pass')}
                    </div>

                    <div className="authorization-page--tab log_in">
                        <div className="authorization-page--title">{t('forgot_pass_promo')}</div>
                        <br/>

                        <div className='contact-data-form'>
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off">
                                <div className="contact-data-form--row">
                                    <TextField fullWidth placeholder={ t('email')+'*' }/>
                                </div>
                                <br/>

                                <div className="contact-data-form--row">
                                    <button className="base-orange-btn black set_up">{t('restore')}</button>
                                </div>
                            </Box>
                            <br/>
                            <a href="/authorization" className='forgot-password'>{t('home_page')}</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
export default ForgotPass
