import React from 'react';
import FilterForm from './sub/FilterForm';

const Filter = () => {

    return (
        <React.Fragment>
            <FilterForm />
        </React.Fragment>
    );
};

Filter.propTypes = {

};

export default Filter;
