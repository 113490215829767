export const FILTER_DATA={
    SET_FROM:'FILTER_DATA@SET_FROM',
    SET_TO:'FILTER_DATA@SET_TO',
    SET:'FILTER_DATA@SET',
    SET_SELECTED_FROM:'FILTER_DATA@SET_SELECTED_FROM',
    SET_SELECTED_TO:'FILTER_DATA@SET_SELECTED_TO',

    SET_SELECTED_DATE_FROM:'FILTER_DATA@SET_SELECTED_DATE_FROM',
    SET_SELECTED_DATE_TO:'FILTER_DATA@SET_SELECTED_DATE_TO',
}
export const ROUTE_SEARCH_DATA={
    SET_DATA:'ROUTE_SEARCH_DATA@SET_DATA',
}
export const AUTH={
    SET_USER:'AUTH@SET_USER',
    SET_IS_AUTH:'AUTH@IS_AUTH',
    SET_ACCESS_TOKEN:'AUTH@ACCESS_TOKEN',

    REMOVE_USER:'AUTH@REMOVE_USER',
}
//deprecated rm later
export const ORDER={
    // SET_PREV_TOTAL:'ORDER@SET_PREV_TOTAL',
    SET_ORDER:'ORDER@SET_ORDER',
    CLEAR_ORDER:'ORDER@ CLEAR_ORDER',
}
