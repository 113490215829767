import React, { useState, useEffect } from 'react';
import TopMenu from '../../components/TopMenu';
import Footer from '../../components/Footer';
import LogIn from './LogIn';
import SignUp from './SignUp';
import BrandName from '../../components/Ui/BrandName/BrandName';
import BreadCrumbs from '../../components/Ui/BreadCrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';

const Authorization = () => {
    const { t, i18n, ready } = useTranslation();
    const TabsList = [
        {
            title: t('login'),
            value: 'log_in'
        },
        {
            title: t( 'registration'),
            value: 'sign_up'
        }
    ]
    const [ currentTabIndex, setCurrentTabIndex ] = useState(0)
    const renderTabContent = () => {
        switch (currentTabIndex){
            case 0:
                return <LogIn />
            case 1:
                return <SignUp />
        }
    }
    const breadcrumbsData= [ { text:t('home_page'),href:'/' },{ text:t('login'),href:false } ]

    return(
        <div className='page page-content'>
            <TopMenu/>
            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={ breadcrumbsData } />
                </div>
            </div>

            <div className="page-container">
                <div className="authorization-page">
                    <div className="authorization-page--head">
                        {t('welcome')} <BrandName/>
                    </div>
                    <div className={ `authorization-page--tab ${ TabsList[ currentTabIndex ].value }` }>
                        <ul className="authorization-page--tab_list">
                            {TabsList.map((obj, i)=> {
                                return <li key={ i }
                                           onClick={ () => setCurrentTabIndex(i) }
                                           className={ currentTabIndex === i ? 'authorization-page--tab_list-item current-item'
                                               : 'authorization-page--tab_list-item' }>{TabsList[ i ].title}</li>
                            })}
                        </ul>
                        <div className="authorization-page--tab_content">
                            {renderTabContent()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Authorization
