import React, {Fragment, useState, useEffect} from 'react';
import {Link} from "react-router-dom";

const ToggleDecorator = ({head = false, content = false}) => {
    const [toggle, setToggle] = useState(true)
    const [isDesktop, setIsDesktop] = useState(true)

    useEffect(() => {
        setToggleOnDesktop()
        window.addEventListener('resize', setToggleOnDesktop);

        return () => {
            window.removeEventListener('resize', setToggleOnDesktop);
        };
    }, []);

    function setToggleOnDesktop() {
        if (document.getElementsByTagName('body')[0].clientWidth > 768) {
            setIsDesktop(true)
            setToggle(true)
        } else {
            setIsDesktop(false)
            setToggle(false)
        }
    }

    if (!head || !content) return null

    const ToggleFooterLinksShow = () => {
        if (isDesktop) {
            setToggle(true)
        } else {
            setToggle(!toggle)
        }
    }

    return (
        <div className='toggle-wrap'>
            <div onClick={ToggleFooterLinksShow} className={toggle ? "toggle-head open" : "toggle-head"}>
                {head} <span className="toggle-head--arrow">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.50893 0.651785C9.56845 0.711309 9.59822 0.779762 9.59822 0.857142C9.59822 0.934524 9.56845 1.00298 9.50893 1.0625L5.34821 5.22321C5.28869 5.28274 5.22024 5.3125 5.14286 5.3125C5.06548 5.3125 4.99702 5.28274 4.9375 5.22321L0.776786 1.0625C0.717262 1.00298 0.6875 0.934524 0.6875 0.857142C0.6875 0.779762 0.717262 0.711309 0.776786 0.651785L1.22321 0.205357C1.28274 0.145833 1.35119 0.116071 1.42857 0.116071C1.50595 0.116071 1.5744 0.145833 1.63393 0.205357L5.14286 3.71429L8.65179 0.205357C8.71131 0.145833 8.77976 0.116071 8.85714 0.116071C8.93452 0.116071 9.00298 0.145833 9.0625 0.205357L9.50893 0.651785Z"
                            fill="#222F3E"/>
                    </svg>
                </span>
            </div>

            <div className="toggle-content">
                {toggle && <div className='fade'>
                    {content}
                </div>}
            </div>
        </div>
    )
}

const Footer = props => {
    return (
        <React.Fragment>
            <footer className={'footer'}>
                <section className={'links-block'}>
                    <section className={'container'}>
                        <img className={'footer-logo'} src={'/images/footer-logo.svg'}
                             alt="Footer logo" width={'141'}
                             height={'106'}/>

                        <img className="main-logo" src="/images/logo.svg" alt="Logotype Superpilet" width="240"
                             height="78"/>

                        <div className={'links-column'}>
                            <ToggleDecorator
                                head={<p className={'links-caption'}><strong>О КОМПАНИИ</strong></p>}
                                content={<Fragment>
                                    <a className={'links'} href="/about">О нас</a>
                                    <a className={'links'} href="">Договор оферты</a>
                                    <a className={'links'} href="">Политика конфиденциальности</a>
                                    <a className={'links'} href="">Контакты</a>
                                </Fragment>}
                            />
                        </div>

                        <div className={'links-column'}>
                            <ToggleDecorator
                                head={<p className={'links-caption'}><strong>ПОЛЬЗОВАТЕЛЯМ</strong></p>}
                                content={<Fragment>
                                    <Link to={'/news'} className={'links'}>Новости</Link>
                                    <a className={'links'} href="">Вопросы/ответы</a>
                                    <a className={'links'} href="">Важно знать</a>
                                </Fragment>}
                            />
                        </div>

                        <div className={'links-column'}>
                            <ToggleDecorator
                                head={<p className={'links-caption'}><strong>ПАРТНЕРАМ</strong></p>}
                                content={<Fragment>
                                    <a className={'links'} href="">Перевозчикам</a>
                                    <a className={'links'} href="">Агентам</a>
                                    <a className={'links'} href="">Партнерская программа</a>
                                </Fragment>}
                            />
                        </div>

                        <div className={'news'}>
                            <ToggleDecorator
                                head={<p className={'links-caption'}><strong>НОВОСТИ</strong></p>}
                                content={<Fragment>
                                    <p className={'subscribe-text'}>Подпишитесь на рассылку, чтобы первым получать
                                        интересные предложения, новости и скидки от Superpilet!
                                    </p>

                                    <div className={'subscribe-form'}>
                                        <input className={'subscribe-input'} type="email" placeholder="Email Address"/>
                                        <button className={'button-link orange'}>Подписаться</button>
                                    </div>
                                </Fragment>}
                            />
                        </div>
                    </section>

                    <img className={'footer-button-image'} src={'/images/footer-button-image.svg'}
                         alt="Footer button image" width={'60'}
                         height={'60'} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}/>
                </section>

                <section className={'copyright-block'}>
                    <section className={'container'}>
                        <p className={'copyright-text'}>Copyright © 2021 All Rights Reserved.</p>
                    </section>
                </section>
            </footer>
        </React.Fragment>
    );
};

Footer.propTypes = {};

export default Footer;
