import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Logo from '../../Ui/Logo';

// todo honir modals
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const BaseDialog = props => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={ props.open }
            onClose={ () => props.onClose(false) }
            className='ticket-registration--dialog register-success-dialog'
            scroll={ 'paper' }>
            <DialogTitle>
                <Logo/>
                <h3>{t('account_success')}</h3>
            </DialogTitle>

            <DialogContent dividers={ scroll === 'paper' }>
                <DialogContentText>
                    <Box sx={ style }>
                        {props.children}
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
};

BaseDialog.propTypes = {
 open:PropTypes.bool.isRequired,
 onClose:PropTypes.func.isRequired,
 headerText:PropTypes.string.isRequired,
};

export default BaseDialog;
