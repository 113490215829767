import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import SelectCountries from "../components/Ui/Select/SelectCountries/SelectCountries";
const style = {
    fontSize: '12px',
    color: '#d20e0e',
    position: 'relative',
    marginTop: '-21px',
    marginLeft:'21px'
};
class CountriesValidatorElement extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, value, ...rest } = this.props;
        return (
            <div>
                <SelectCountries
                    { ...rest }
                    value={this.props.value}
                    onSelect={this.props.onSelect}
                    ref={ (r) => { this.input = r; } }
                />
                {this.errorText()}
            </div>
        );
    }
    errorText() {
        const { isValid } = this.state;
        if (isValid) {
            return null;
        }
        return (
            <div style={ style }>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default CountriesValidatorElement;
