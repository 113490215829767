import React from 'react';
import PropTypes from 'prop-types';

const RouteFromHere = props => {

    if (!props.stations) {
        return null;
    }

    let {0: first, [props.stations.length - 1]: last} = props.stations;

    return (
        <div className="route-info__head">
            <div className="route-start">
                <div className="start-name">{first.name}</div>
                <div className="start-address">{first.address}</div>
            </div>

            <div className="route-end">
                <div className="end-name">{last.name}</div>
                <div className="start-address">{last.address}</div>
            </div>
        </div>
    );
};

RouteFromHere.propTypes = {
    stations: PropTypes.array.isRequired,
};

export default RouteFromHere;
