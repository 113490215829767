import React, { useEffect, useState } from 'react';
import ToggleDetails from '../../../components/Ui/ToggleDetails';
import { useTranslation, withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import useFormField from '../../../hooks/useFormField';
import AwaitDialog from '../../../components/Dialogs/AwaitDialod/AwaitDialog';
import useRequest from '../../../hooks/useRequest';
import { getOrder } from '../../../api/listAPI';
import { clearOrder, setOrder } from '../../../redux/reducers/orderReducer';
import { useNavigate } from 'react-router-dom';
import { TextValidator } from 'react-material-ui-form-validator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-phone-input-2/lib/style.css'
import { firstEngineError } from '../../../utils/responceFormValidatorHelper';
import { useToasts } from 'react-toast-notifications';
import CheckboxValidatorElement from '../../../validators/CheckboxValidatorElement';
import { compose } from 'redux';
import { ValidatorsContainer } from '../../../validators/ValidatorsContainer';
import PhoneValidatorElement from '../../../validators/PhoneValidatorElement';
import CountriesValidatorElement from '../../../validators/CountriesValidatorElement';
import ListOrders from "../../../components/ListOrders/ListOrders";
import PropTypes from "prop-types";

const Pay = (props) => {
    const { t } = useTranslation();
    const [ showDetails, setShowDetails ] = useState(false)
    const navigate = useNavigate();
    const { addToast } = useToasts()
    const surname = useFormField(props.user.last_name,'last_name',t('surname')+'*' )
    const name = useFormField(props.user.name,'name',t('name')+'*')
    // const phone = useFormFieldSimple(props.user.phone,'phone')
    const email = useFormField(props.user.email,'email', t('email')+'*')

    const [ countryCode,setCountryCode ]=useState(props.user.country_code)
    const [ phone,setPhone ]=useState(props.user.phone)
    const city = useFormField(props.user.city,'city',t('city')+'*')
    const street = useFormField(props.user.street,'street',t('street')+'*')
    const zip = useFormField(props.user.zip,'zip',t('zip')+'*')
    const [ agreeComercical,setAgreeComercical ] = useState(false)
    const [ agreePublicOffert,setAgreePublicOffert ] = useState(false)
    const [ agreePublicPersonal,setAgreePublicPersonal ] = useState(false)

    const [ showOrder, setShowOrder ] = useState(false)
    const [ paymentLink, setpaymenLink ] = useState(false)

    const [ data, loading, reUpdate,error ] = useRequest(getOrder.bind(null, {
            'total': props.total,
            'email': email.value,
            'payment_city': city.value,
            'payment_iso_code_2': countryCode,
            'payment_address_1': street.value,
            'payment_postcode': zip.value
        })
    )
    const [ showAwait,setShowAwait ]=useState(loading)
    useEffect(()=>{
        if(props.user){
            name.onChange({ target:{ value:props.user.name } })
            surname.onChange({ target:{ value:props.user.last_name } })
            email.onChange({ target:{ value:props.user.email } })
            setPhone(props.user.phone)
            setCountryCode(props.user.country_code)
            city.onChange({ target:{ value:props.user.city } })
            street.onChange({ target:{ value:props.user.street } })
            zip.onChange({ target:{ value:props.user.zip } })
        }
    },[ props.isAuth ])

    useEffect(() => {
        if (data) {
            // props.setOrder(data.collection.order)
            if(data.collection.order.payment_link){
                setTimeout(()=>{
                    setShowAwait(true);
                },500)

                window.location.href = data.collection.order.payment_link;
            }
        }
    }, [ data ])
    useEffect(() => {
        if (props.order) {
            setShowOrder(true)
        } else {
            setShowOrder(false)
        }
    }, [ props.order ])

    useEffect(()=>{
        if(error){
            addToast(firstEngineError(error.response.data), { appearance: 'error', autoDismiss: true });
        }
    },[ error ])

    useEffect(()=>{
        setShowAwait(loading)
    },[ loading ])

    useEffect(()=>{
        // ValidatorForm.addValidationRule('isTruthy', value => value);
        // custom rule will have name 'isPasswordMatch'
        // ValidatorForm.addValidationRule('isTruthy', value => { return false;});
        console.log('constructor')
    },[])

    return (

        <React.Fragment>

             <div className="route-head">
                 {showDetails && <ListOrders data={props.data} /> }
             </div>


            <ToggleDetails action={ f => setShowDetails(!showDetails) }/>
            {/* Ent Toggle Details */}
            <ValidatorForm
                // key={seats.map(s => s.place)}
                onSubmit={ f=>{
                    // addToast('send to server', { appearance: 'success', autoDismiss: true });
                    // debugger
                    reUpdate();
                } }
            >
                <div className="ticket-issuance">
                    <div className="block-title">{t('ticket_issuance')}</div>
                    <div className='contact-data-form ticket-issuance-form'>
                        <Box
                        component="form"
                        noValidate
                        autoComplete="off">
                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('name')}</div>

                                        {/*<TextField fullWidth placeholder={t('name') + '*'} {...name} />*/}
                                        <TextValidator
                                        fullWidth
                                        { ...name }
                                        validators={ [ 'required' ] }
                                        errorMessages={ [ t('required_field') ] }
                                    />

                                        <div className="contact-data-form--prompt">{t('write_your_name')}</div>
                                    </div>
                                </div>

                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('surname')}</div>

                                        <TextValidator
                                        fullWidth
                                        { ...surname }
                                        validators={ [ 'required' ] }
                                        errorMessages={ [ t('required_field') ] }
                                    />
                                        {/*<TextField fullWidth placeholder={t('surname') + '*'} {...surname} />*/}
                                    </div>
                                </div>
                            </div>

                            <p className='prompt-text'>{t('promo_correct_email')}</p>

                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('email')}</div>
                                        <TextValidator
                                        fullWidth
                                        { ...email }
                                        validators={ [ 'required','isEmail' ] }
                                        errorMessages={ [ t('required_field'),t('is_not_email') ] }
                                    />
                                        {/*<TextField fullWidth placeholder={t('email')} {...email}/>*/}
                                    </div>
                                </div>

                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('phone')}</div>
                                        <PhoneValidatorElement
                                            value={ phone }
                                            onChange={ setPhone }

                                            country={ 'ee' }
                                            validators={ [ 'required','minPhone' ] }
                                            errorMessages={ [ t('required_field'),t('required_field') ] }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className="form-row checkbox--row">
                                        <div className="checkbox--wrap">
                                            <CheckboxValidatorElement
                                        // validators={ [ 'isTruthy' ] }
                                        // errorMessages={ [ t('required_field') ] }
                                        value={ agreeComercical }
                                        onChange={ event=>{setAgreeComercical(event.target.checked)} }
                                        />
                                        </div>

                                        <p>{t('promo_send_me')}</p>
                                    </div>
                                </div>

                                <div className="form-row--cell">
                                    <div className="contact-data-form--prompt">{t('i_have_promo')}</div>
                                </div>
                            </div>

                            <p className='prompt-text'>{t('promo_payment_data')}</p>

                            {/*Sam start*/}
                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className="contact-data-form--row country-cell">
                                        <div className="contact-data-form--label">{t('country')}</div>

                                        <CountriesValidatorElement
                                            value={ countryCode }
                                            onSelect={ setCountryCode }
                                            validators={ [ 'required' ] }
                                            errorMessages={ [ t('required_field') ] }
                                        />

                                    </div>
                                </div>

                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('city')}</div>

                                        <TextValidator fullWidth
                                                   { ...city }
                                                   validators={ [ 'required' ] }
                                                   errorMessages={ [ t('required_field') ] }
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('street')}</div>
                                        <TextValidator fullWidth
                                                       { ...street }
                                                       validators={ [ 'required' ] }
                                                       errorMessages={ [ t('required_field') ] }
                                        />
                                    </div>
                                </div>

                                <div className="form-row--cell">
                                    <div className="contact-data-form--row">
                                        <div className="contact-data-form--label">{t('zip')}</div>
                                        <TextValidator fullWidth
                                                       { ...zip }
                                                       validators={ [ 'required' ] }
                                                       errorMessages={ [ t('required_field') ] }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Sam end*/}

                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className='form-row checkbox--row'>
                                        <div className="checkbox--wrap">
                                            <CheckboxValidatorElement
                                                value={ agreePublicOffert }
                                                onChange={ event=>{setAgreePublicOffert(event.target.checked)} }
                                                validators={ [ 'isTruthy' ] }
                                                errorMessages={ [ t('required_field') ] }
                                            />
                                        </div>
                                        <p>{t('i_accept')} <a href="">{t('public_offer')}</a>, <a
                                        href="">{t('privacy_policy')}</a> и <a href="">{t('refund')}</a>.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-row--cell">
                                    <div className='form-row checkbox--row'>
                                        <div className="checkbox--wrap">
                                            <CheckboxValidatorElement
                                                value={ agreePublicPersonal }
                                                onChange={ event=>{setAgreePublicPersonal(event.target.checked)} }
                                                validators={ [ 'isTruthy' ] }
                                                errorMessages={ [ t('required_field') ] }
                                            />
                                        </div>

                                        <p>{t('i_have')} <a href="">{t('agree_personal')}</a></p>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>

                {/* NO delete */}
                {/*<div className="payment-method">*/}
                {/*    <div className="block-title">Выбрать способ олаты</div>*/}
                {/*    <div className='contact-data-form'>*/}
                {/*        <Box*/}
                {/*            component="form"*/}
                {/*            noValidate*/}
                {/*            autoComplete="off">*/}
                {/*            <PaymentsBanks/>*/}
                {/*        </Box>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="pay-action--row">
                    {props.total > 0 && <button className='base-orange-btn'>{t('pay') + ' ' + (props.total / 100) + '€'}</button>}
                    <p>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                            d="M15.625 20H4.375C3.34167 20 2.5 19.1592 2.5 18.125V9.375C2.5 8.34076 3.34167 7.5 4.375 7.5H15.625C16.6583 7.5 17.5 8.34076 17.5 9.375V18.125C17.5 19.1592 16.6583 20 15.625 20ZM4.375 8.75C4.03076 8.75 3.75 9.03 3.75 9.375V18.125C3.75 18.47 4.03076 18.75 4.375 18.75H15.625C15.9692 18.75 16.25 18.47 16.25 18.125V9.375C16.25 9.03 15.9692 8.75 15.625 8.75H4.375Z"
                            fill="#898989"/>
                            <path
                            d="M14.375 8.75C14.03 8.75 13.75 8.47 13.75 8.125V5C13.75 2.93243 12.0676 1.25 10 1.25C7.93243 1.25 6.25 2.93243 6.25 5V8.125C6.25 8.47 5.97 8.75 5.625 8.75C5.28 8.75 5 8.47 5 8.125V5C5 2.24243 7.24243 0 10 0C12.7576 0 15 2.24243 15 5V8.125C15 8.47 14.72 8.75 14.375 8.75Z"
                            fill="#898989"/>
                        </svg>
                        {t('security_promo')}
                    </p>
                </div>
            </ValidatorForm>
            {/*<div className="pay-action--row">*/}
            {/*    <button className='base-orange-btn'>ОПЛАТИТЬ</button>*/}

            {/*    <p>*/}
            {/*        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <path*/}
            {/*                d="M15.625 20H4.375C3.34167 20 2.5 19.1592 2.5 18.125V9.375C2.5 8.34076 3.34167 7.5 4.375 7.5H15.625C16.6583 7.5 17.5 8.34076 17.5 9.375V18.125C17.5 19.1592 16.6583 20 15.625 20ZM4.375 8.75C4.03076 8.75 3.75 9.03 3.75 9.375V18.125C3.75 18.47 4.03076 18.75 4.375 18.75H15.625C15.9692 18.75 16.25 18.47 16.25 18.125V9.375C16.25 9.03 15.9692 8.75 15.625 8.75H4.375Z"*/}
            {/*                fill="#898989"/>*/}
            {/*            <path*/}
            {/*                d="M14.375 8.75C14.03 8.75 13.75 8.47 13.75 8.125V5C13.75 2.93243 12.0676 1.25 10 1.25C7.93243 1.25 6.25 2.93243 6.25 5V8.125C6.25 8.47 5.97 8.75 5.625 8.75C5.28 8.75 5 8.47 5 8.125V5C5 2.24243 7.24243 0 10 0C12.7576 0 15 2.24243 15 5V8.125C15 8.47 14.72 8.75 14.375 8.75Z"*/}
            {/*                fill="#898989"/>*/}
            {/*        </svg>*/}

            {/*        Ваши платежные и личные данные надежно защищены.*/}
            {/*    </p>*/}
            {/*</div>*/}
            <AwaitDialog open={ showAwait } onClose={ f => f }/>
            <AwaitDialog open={ props.loading } onClose={ f => f }/>
        </React.Fragment>

    )
}
const mapDispatchToProps = {
    setOrder,
    clearOrder
}
const mapStateToProps = (state) => {

    return {
        isAuth: state.auth.isAuth,
        user: state.auth.user,
        order: state.order.order,
    };
}
Pay.defaultProps={
    data:[]
}

Pay.propTypes = {
    loading:PropTypes.bool.isRequired,
    data:PropTypes.array.isRequired,
    total:PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(compose(ValidatorsContainer)(withTranslation()(Pay)) );
