import React from 'react'

const LocationMarker = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_147_3278)">
                <path d="M15.4297 12.6953C15.1602 12.6953 14.9414 12.9141 14.9414 13.1836C14.9414 13.4531 15.1602 13.6719 15.4297 13.6719C15.6992 13.6719 15.918 13.4531 15.918 13.1836C15.918 12.9141 15.6992 12.6953 15.4297 12.6953Z" fill="#98969B"/>
                <path d="M4.98864 18.0559L0.105826 24.2083C-0.0107132 24.3549 -0.0328384 24.5554 0.0486054 24.724C0.130049 24.8928 0.300948 25 0.488249 25H24.5117C24.699 25 24.8699 24.8928 24.9513 24.724C25.033 24.5554 25.0107 24.3549 24.8941 24.2083L20.0113 18.0559C19.9188 17.9392 19.7778 17.8711 19.6289 17.8711H15.3516L19.0033 12.1437C19.8599 10.8595 20.3125 9.36184 20.3125 7.8125C20.3125 3.50475 16.8079 0 12.5 0C8.19203 0 4.68747 3.50475 4.68747 7.8125C4.68747 9.36184 5.14008 10.8593 5.99667 12.1437L9.64829 17.8711H5.37106C5.2221 17.8711 5.08134 17.9392 4.98864 18.0559ZM3.16559 21.9238H6.11798L4.45172 24.0234H1.49914L3.16559 21.9238ZM10.271 18.8477L11.6096 20.9473H8.13977L9.80622 18.8477H10.271ZM14.7291 18.8477H15.1939L16.8602 20.9473H13.3905L14.7291 18.8477ZM17.6353 21.9238L19.3016 24.0234H5.69836L7.36481 21.9238H17.6353ZM20.5484 24.0234L18.882 21.9238H21.8345L23.5008 24.0234H20.5484ZM21.0594 20.9473H18.107L16.4406 18.8477H19.3931L21.0594 20.9473ZM6.81187 11.6058C6.06095 10.4816 5.66403 9.16996 5.66403 7.8125C5.66403 4.0432 8.73067 0.976562 12.5 0.976562C16.2693 0.976562 19.3359 4.0432 19.3359 7.8125C19.3359 9.16996 18.939 10.4816 18.1883 11.6058C18.1862 11.6087 18.1844 11.6116 18.1825 11.6144C17.8619 12.1174 12.747 20.1401 12.5 20.5273C11.8787 19.5528 7.45007 12.6066 6.8174 11.6144C6.81569 11.6116 6.81378 11.6087 6.81187 11.6058ZM8.55939 18.8477L6.89313 20.9473H3.94055L5.607 18.8477H8.55939Z" fill="#98969B"/>
                <path d="M12.5 12.6953C15.1806 12.6953 17.3828 10.5246 17.3828 7.8125C17.3828 5.12009 15.1924 2.92969 12.5 2.92969C9.80759 2.92969 7.61719 5.12009 7.61719 7.8125C7.61719 10.5253 9.82018 12.6953 12.5 12.6953ZM12.5 3.90625C14.654 3.90625 16.4062 5.65853 16.4062 7.8125C16.4062 9.9865 14.6399 11.7188 12.5 11.7188C10.3596 11.7188 8.59375 9.9865 8.59375 7.8125C8.59375 5.65853 10.346 3.90625 12.5 3.90625Z" fill="#98969B"/>
                <path d="M14.5569 14.3614C14.3261 14.2222 14.0259 14.2966 13.8866 14.5276L12.0819 17.5217C11.9427 17.7527 12.017 18.0527 12.248 18.192C12.48 18.3318 12.7796 18.2559 12.9183 18.0258L14.723 15.0317C14.8622 14.8007 14.7879 14.5007 14.5569 14.3614Z" fill="#98969B"/>
            </g>
            <defs>
                <clipPath id="clip0_147_3278">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default LocationMarker


