import axios from 'axios';
// import Config from '@/constants/Config';
import Config from '../constants/Config';
import { header, fileHeader } from './headerAPI';
import {getStore} from "../app";
const baseUrl = Config.api_uri + 'api';

const api=axios.create({
    withCredentials:true,
    baseURL:baseUrl,
})
//use jwt
api.interceptors.request.use((config)=>{
    const locState = getStore().getState();
    const access_token=locState.auth.access_token
    // config.headers.Authorization=`Bearer ${ localStorage.getItem('token') }`;
    // console.log(access_token);
    config.headers.Authorization=`Bearer ${ access_token }`;
    return config;
})

export default api;

//deprecated ??
const cancelTokenSource = {}

export function fetchList(path, page, limit, filter = {}) {
  if (cancelTokenSource[ path ]) {
    cancelTokenSource[ path ].cancel()
  }
  cancelTokenSource[ path ] = axios.CancelToken.source()
  return axios.get(baseUrl + path, {
    headers: header(),
    params: { page, limit, ...filter },
    cancelToken: cancelTokenSource[ path ].token,
  })
}

export function getData(urlFragment, params = {}) {
  return axios.get(baseUrl + urlFragment, { headers: header(), params });
}

export function postDataV1(urlFragment, data, files = false) {
  const payload = new FormData();
  for (const [ name, value ] of Object.entries(data || {})) {
    if (![ null, undefined ].includes(value)) {
      payload.append(name, value)
    }
  }
  return axios.post(baseUrl + urlFragment, payload, {
    headers: fileHeader()
  });
}

export function postData(urlFragment, arrayData, files = false) {
  const formData = new FormData();
  Object.keys(arrayData).forEach(key => {
    formData.append(key, arrayData[ key ]);
  });
  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[ i ]);
    }
  }
    // return api.post(urlFragment, formData, {
    //     headers: fileHeader()
    // });
  return axios.post(baseUrl + urlFragment, formData, {
    headers: fileHeader()
  });
}

export function putData(urlFragment, arrayData) {
  const formData = new FormData();
  Object.keys(arrayData).forEach(key => {
    formData.append(key, arrayData[ key ]);
  });
  return axios.post(baseUrl + urlFragment, formData, {
    headers: fileHeader()
  });
}

export function deleteData(urlFragment, arrayData = []) {
  return axios.delete(baseUrl + urlFragment, { headers: header() });
}

export function putDataCustom(urlFragment, formData) {
  return axios.post(baseUrl + urlFragment, formData, {
    headers: fileHeader()
  });
}

export function postDataObject(urlFragment, objectData = {}) {
  return axios.post(baseUrl + urlFragment, objectData, {
    headers: header()
  });
}

export function putDataObject(urlFragment, objectData = {}) {
  return axios.put(baseUrl + urlFragment, objectData, {
    headers: header()
  });
}
