import { useEffect, useState } from 'react';

export default function useRequest(request) {
      const [ data,setData ]=useState(null);
      const [ loading,setLoading ]=useState(false);
      const [ error,setError ]=useState('');

     function fetchData(){
       setError('')
       setLoading(true);
       request()
         .then(responce=>{
             setData(responce.data);
         })
         .catch(err=>setError(err))
         .finally(()=>{setLoading(false)})
     }
     function reUpdate(){
       fetchData();
     }
    useEffect(()=>{
     // reUpdate()
    },[])
    return [ data,loading,reUpdate,error, ]
}
