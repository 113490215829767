import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const BreadCrumbs = props => {
    const withHref = e => {
        const icon = (e) => {
            if (!e.icon) return null
            return (<i className="icon_wrap">{e.icon}</i>)
        }

        if (e.href) {
            return <Link to={e.href}>{icon(e)} {e.text}</Link>
        } else {
            return <a href="#">{icon(e)} {e.text}</a>
        }
    }

    return (
        <ul className='custom-breadcrumbs--list'>
            {props.data.map((e, i) => {
                let classNames = props.data.length - 1 == i ? 'custom-breadcrumbs--item current' : 'custom-breadcrumbs--item';
                return <li key={i} className={classNames}>
                    {withHref(e)}
                </li>
            })}
        </ul>
    );
};

BreadCrumbs.propTypes = {
    data: PropTypes.array.isRequired,
};

export default BreadCrumbs;
