import React from 'react';
import PropTypes from 'prop-types';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {useTranslation} from "react-i18next";
import BreadCrumbs from "../components/Ui/BreadCrumbs/BreadCrumbs";
import Logo from "../components/Ui/Logo";
import Rules from "../components/Rules";

const About = props => {
    const {t, i18n, ready} = useTranslation();
    const breadcrumbsData = [
        {text: t('Главная'), href: '/'},
        {text: t('about_us'), href: false}
    ]
    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={breadcrumbsData}/>
                </div>
            </div>

            <div className="page-container">
                <div className="logo-container">
                    <Logo/>
                </div>

                <p className="about-text">
                    {t('Точный график их движения вы всегда найдете у нас на сайте, а также сможете забронировать и выкупить билеты на подходящий для вас рейс. Путешествия на автобусах — это выгодно и быстро: билет стоит дешевле, чем на самолет, а до места назначения вы доберетесь гораздо быстрее, чем на поезде.')}
                </p>

                <div className="about-as-banner">
                    <div className="about-as-banner__cell">
                        <img src='/images/about_us_banner.png' alt='about-us'/>
                    </div>

                    <div className="about-as-banner__cell">
                        <div className="about-as-banner__text">
                            <h1>ЗАКАЖИТЕ НАШИ УСЛУГИ —</h1>

                            <h2>ПОЛУЧИте ЛУЧШИЕ ЦЕНЫ И СЕРВИС</h2>

                            <p>Мы можем предложить нашим клиентам поездки на автобусах SETRA S415 GT-HD (49+2+1 мест), SETRA S315 GT-HD VIP (49+2+1 мест), VDL-BOVA (51 + 2 мест), Mercedes Atego 1223 L (28 мест) ,Mercedes Sprinter (16 мест) и Mercedes Sprinter (20 мест),Volkswagen Caravelle (8 мест). Комфортабельные салоны этих автобусов оборудованы телевизорами, DVD проигрывателями, кондиционерами,
                                GPS-навигацией и откидывающимися сиденьями.</p>
                        </div>
                    </div>
                </div>

                <div className="about-text-block">
                    <p>Наша компания успешно работает на рынке регулярных и нерегулярных перевозок в течение многих лет.
                        Такой опыт помог скорректировать и оптимизировать деятельность наших сотрудников,
                        чтобы наладить надежный и безопасный способ доставки товаров из европейских государств в Эстонию.</p>

                    <p>Наши постоянные клиенты по достоинству оценили преимущества сотрудничества, благодаря широкому спектру предоставляемых услуг:</p>

                    <ul>
                        <li>Обязательное страхование товаров;</li>

                        <li>Помощь в прохождении таможенного контроля;</li>

                        <li>Удобная система отслеживания перемещения грузов;</li>

                        <li>Быстрый прием и обработка заявок на отправку товаров;</li>

                        <li>Разработка индивидуальных маршрутов для доставки грузов;</li>

                        <li>Организация перевозок несколькими видами транспорта;</li>

                        <li> Оформление необходимых сопроводительных документов на отправления;</li>

                        <li>Общение сотрудников с клиентами на нескольких популярных европейских языках.</li>
                    </ul>

                    <p>Компания осуществляет индивидуальный подход к каждому заказчику.
                        Мы предлагаем гибкую систему цен, которая формируется на основе нескольких факторов,
                        включая регулярность и объем перевозок. Четкое выполнение поставленных задач нашими сотрудниками и контроль всех логистических
                        процессов помогают организовать максимально безопасные, быстрые и качественные грузоперевозки.</p>
                </div>

                <Rules/>
            </div>

            <Footer/>
        </div>
    );
};

About.propTypes = {};

export default About;
