import React, {useEffect} from 'react';

import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { setFilterDateFrom, setFilterDateTo } from '../../../redux/reducers/filterReducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DatePickerIcon = () => {
    return (
        <div className="date-picker-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.6562 1.5625H16.7188V0H15.1562V1.5625H4.84375V0H3.28125V1.5625H2.34375C1.05141 1.5625 0 2.61391 0 3.90625V17.6562C0 18.9486 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9486 20 17.6562V3.90625C20 2.61391 18.9486 1.5625 17.6562 1.5625ZM18.4375 17.6562C18.4375 18.087 18.087 18.4375 17.6562 18.4375H2.34375C1.91297 18.4375 1.5625 18.087 1.5625 17.6562V7.34375H18.4375V17.6562ZM18.4375 5.78125H1.5625V3.90625C1.5625 3.47547 1.91297 3.125 2.34375 3.125H3.28125V4.6875H4.84375V3.125H15.1562V4.6875H16.7188V3.125H17.6562C18.087 3.125 18.4375 3.47547 18.4375 3.90625V5.78125Z"
                    fill="#E97D35"/>
                <path d="M4.53125 8.98438H2.96875V10.5469H4.53125V8.98438Z" fill="#E97D35"/>
                <path d="M7.65625 8.98438H6.09375V10.5469H7.65625V8.98438Z" fill="#E97D35"/>
                <path d="M10.7812 8.98438H9.21875V10.5469H10.7812V8.98438Z" fill="#E97D35"/>
                <path d="M13.9062 8.98438H12.3438V10.5469H13.9062V8.98438Z" fill="#E97D35"/>
                <path d="M17.0312 8.98438H15.4688V10.5469H17.0312V8.98438Z" fill="#E97D35"/>
                <path d="M4.53125 12.1094H2.96875V13.6719H4.53125V12.1094Z" fill="#E97D35"/>
                <path d="M7.65625 12.1094H6.09375V13.6719H7.65625V12.1094Z" fill="#E97D35"/>
                <path d="M10.7812 12.1094H9.21875V13.6719H10.7812V12.1094Z" fill="#E97D35"/>
                <path d="M13.9062 12.1094H12.3438V13.6719H13.9062V12.1094Z" fill="#E97D35"/>
                <path d="M4.53125 15.2344H2.96875V16.7969H4.53125V15.2344Z" fill="#E97D35"/>
                <path d="M7.65625 15.2344H6.09375V16.7969H7.65625V15.2344Z" fill="#E97D35"/>
                <path d="M10.7812 15.2344H9.21875V16.7969H10.7812V15.2344Z" fill="#E97D35"/>
                <path d="M13.9062 15.2344H12.3438V16.7969H13.9062V15.2344Z" fill="#E97D35"/>
                <path d="M17.0312 12.1094H15.4688V13.6719H17.0312V12.1094Z" fill="#E97D35"/>
            </svg>
        </div>
    )
}


const FilterSelectCalendarFrom = props => {
    const {t} = useTranslation();
    return (
        <LocalizationProvider dateAdapter={DateAdapter} locale={'ru'}>
            <div className="formBlock small date-picker-wrap">
                <DatePickerIcon/>

                <DatePicker
                    label={t('date_there')}
                    value={props.selectedDateFrom}
                    onChange={(value) => {
                        props.setFilterDateFrom(value.format('YYYY-MM-DD'));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </div>

            <div className="formBlock small date-picker-wrap">
                <DatePickerIcon/>
                <DatePicker
                    label={t('date_back')}
                    value={props.selectedDateTo}
                    onChange={(value) => {
                        props.setFilterDateTo(value.format('YYYY-MM-DD'));
                    }}
                    renderInput={(params) => <TextField {...params} />}

                />
            </div>
        </LocalizationProvider>
    );
};

FilterSelectCalendarFrom.propTypes = {};

const mapDispatchToProps = {
    setFilterDateFrom,
    setFilterDateTo,

}
const mapStateToProps = (state) => {
    return {
        selectedDateFrom: state.filter.selectedDateFrom,
        selectedDateTo: state.filter.selectedDateTo,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectCalendarFrom);
