import api, { getData, postData } from './baseAPI';
import React from 'react';

export function getListRoutes(from, to, dt, dtBack) {
    // return getData(`/bus-trips-schedule/${cityFrom}/${cityTo}`)
    return getData(`/bus-trips-search/${ from }/${ to }${ dt ? ('/' + dt) : '' }${ dtBack ? ('/' + dtBack) : '' }`)
}
export function getStationByAlias(alias) {
    return getData(`/station-by-alias/${ alias }`)
}

export function getOrder(data) {
    return postData('/payment/make-order',data)
}
//with auth
export function updateProfile(data) {
        return api.post('/auth/user-edit',data)
}


export function getCartData() {
    return getData(`/cart-tickets`)
}
