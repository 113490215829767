import React, {useEffect, useState, useRef} from 'react'
import Checkbox from '@mui/material/Checkbox'

const GetTicketSms = ({checked, disabled, onChange}) => {
    return (
        <div className='get-ticket-sms--row'>
            <div className="get-ticket-sms">
                <div className="message--cell">
                    <div className="get-ticket-sms--label">Получить билет смс-сообщением</div>
                    <div className="get-ticket-sms-price">(+0.20€)</div>
                </div>


                <div className="checkbox--wrap">
                    <Checkbox
                        name={'sms'}
                        value={'1'}
                        defaultChecked={checked}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    )
}
export default GetTicketSms
