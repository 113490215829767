import React, { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { removeUser } from '../../../redux/reducers/authReducer';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import useFormField from '../../../hooks/useFormField';
import SelectCountries from '../../../components/Ui/Select/SelectCountries/SelectCountries';
import useRequest from '../../../hooks/useRequest';
import { updateProfile } from '../../../api/listAPI';
import AwaitDialog from '../../../components/Dialogs/AwaitDialod/AwaitDialog';
import { useToasts } from 'react-toast-notifications';
import { firstEngineError } from '../../../utils/responceFormValidatorHelper';

const BuyerDetails = (props) => {
    const { t, i18n, ready } = useTranslation();
    const { addToast } = useToasts()

    const surname = useFormField(props.user.last_name)
    const name = useFormField(props.user.name)
    const patronymic = useFormField(props.user.patronymic)
    const email = useFormField(props.user.email)

    const [ phone,setPhone ] = useState(props.user.phone)

    const [ countryCode,setCountryCode ]=useState(props.user.country_code)
    const city = useFormField(props.user.city)
    const street = useFormField(props.user.street)
    const zip = useFormField(props.user.zip)

    const [ data, loading, reUpdate, error ] = useRequest(updateProfile.bind(null,{
            last_name:surname.value,
            name: name.value,
            patronymic: patronymic.value,
            email: email.value,
            phone: phone,
            country_code: countryCode,
            city: city.value,
            zip: zip.value,
            street: street.value,
        }
    ))

    useEffect(()=>{
        if(props.user){
            name.onChange({ target:{ value:props.user.name } })
            surname.onChange({ target:{ value:props.user.last_name } })
            patronymic.onChange({ target:{ value:props.user.patronymic } })
            email.onChange({ target:{ value:props.user.email } })

            setPhone(props.user.phone)
            setCountryCode(props.user.country_code)
            city.onChange({ target:{ value:props.user.city } })
            street.onChange({ target:{ value:props.user.street } })
            zip.onChange({ target:{ value:props.user.zip } })
        }
    },[ props.isAuth ])

    useEffect(()=>{
        if(error){
            addToast(firstEngineError(error.response.data), { appearance: 'error', autoDismiss: true });
        }
    },[ error ])

    return(
        <React.Fragment>
            <div className='user-account-tab--content_item fade-div'>
                <h1 className="user-account-tab--content_item">{t('buyer_details')}</h1>

                <div className="contact-data-form">
                    <Box
                   component="form"
                   noValidate
                   autoComplete="off">
                        <div className="contact-data-form--fieldset">
                            <div className="contact-data-form--row flex-row">
                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('surname')}</div>

                                    <TextField fullWidth placeholder={ t('surname')+'*' } { ...surname } name={ 'surname' } />
                                </div>

                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('name')}</div>

                                    <TextField fullWidth placeholder={ t('name')+'*' } { ...name }  name={ 'name' }/>
                                </div>

                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('patronymic')}</div>

                                    <TextField fullWidth placeholder={ t('patronymic')+'*' } { ...patronymic } name={ 'patronymic' }/>
                                </div>
                            </div>

                            <div className="contact-data-form--row flex-row">
                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('email')}</div>

                                    <TextField fullWidth placeholder={ t('email')+'*' } { ...email } name={ 'email' }/>
                                </div>

                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('phone')}</div>

                                    <PhoneInput country={ 'ee' } value={ phone } onChange={ setPhone }/>
                                </div>

                                <div className="contact-data-form--row__cell"></div>
                            </div>

                            <div className="contact-data-form--row flex-row">
                                <div className="contact-data-form--row__cell country-cell">
                                    <div className="contact-data-form--label">{t('country')}</div>
                                    <SelectCountries value={ countryCode } onSelect={ setCountryCode }  />
                                </div>

                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('city')}</div>

                                    <TextField fullWidth placeholder={ t('city')+'*' } { ...city } name={ 'city' }/>
                                </div>

                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('zip')}</div>

                                    <TextField fullWidth placeholder={ t('zip')+'*' } { ...zip } name={ 'zip' }/>
                                </div>
                            </div>
                            <div className="contact-data-form--row flex-row">
                                <div className="contact-data-form--row__cell">
                                    <div className="contact-data-form--label">{t('address')}</div>

                                    <TextField fullWidth placeholder={ t('address')+'*' } { ...street } name={ 'street' }/>
                                </div>

                            </div>

                        </div>
                    </Box>

                    <button onClick={ ()=>{
                    reUpdate();
                } } className='button-link orange'>{t('save')}</button>
                </div>
            </div>
            <AwaitDialog
                open={ loading }
                onClose={ f=>f }/>
        </React.Fragment>
    )
}
// export default BuyerDetails

const mapDispatchToProps = {
    removeUser,
}
const mapStateToProps = (state) => {

    return {
        isAuth: state.auth.isAuth,
        user: state.auth.user,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuyerDetails));
