import React, { useState, useEffect } from 'react';
import TopMenu from '../../components/TopMenu';
import BreadCrumbs from '../../components/Ui/BreadCrumbs/BreadCrumbs';
import Footer from '../../components/Footer';
import { useTranslation } from 'react-i18next';
import Button from '../../elements/Button';
import { useNavigate, useParams } from 'react-router-dom';
const NewsList = () => {
    const { t, i18n, ready } = useTranslation();
    const breadcrumbsData = [
        { text: t('Главная'), href: '/' },
        { text: t('Новости'), href: false }
    ]
    const navigate = useNavigate();
    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={ breadcrumbsData }/>
                </div>
            </div>

            <div className="page-container">
                <div className="user-page-content">
                    <div className="user-page-content__title">Новости</div>

                    <div className="news-grid-row">
                        <div className="news-list--item">
                            <div className="news-list--item__thumbnail">
                                <img className='demo' src="/images/news-demo-thumbnail_1.png" alt="news title"/>
                            </div>

                            <div className="news-list--item--title">+190 новых автобусных рейсов от LikeBus</div>

                            <div className="news-list--item-content">
                                <p>К нам присоединился крутой перевозчик LikeBus! Автобусы LikeBus оснащены wi-fi, кондиционерами, ...</p>
                            </div>

                            <div className="news-list--item-action">
                                <Button className={ 'base-orange-btn write initial-width' } variant="contained"> {t('Подробнее')} </Button>
                            </div>
                        </div>

                        <div className="news-list--item">
                            <div className="news-list--item__thumbnail">
                                <img className='demo' src="/images/news-demo-thumbnail_1.png" alt="news title"/>
                            </div>

                            <div className="news-list--item--title">+190 новых автобусных рейсов от LikeBus</div>

                            <div className="news-list--item-content">
                                <p>К нам присоединился крутой перевозчик LikeBus! Автобусы LikeBus оснащены wi-fi, кондиционерами, ...</p>
                            </div>

                            <div className="news-list--item-action">
                                <Button className={ 'base-orange-btn write initial-width' } variant="contained"> {t('Подробнее')} </Button>
                            </div>
                        </div>

                        <div className="news-list--item">
                            <div className="news-list--item__thumbnail">
                                <img className='demo' src="/images/news-demo-thumbnail_1.png" alt="news title"/>
                            </div>

                            <div className="news-list--item--title">+190 новых автобусных рейсов от LikeBus</div>

                            <div className="news-list--item-content">
                                <p>К нам присоединился крутой перевозчик LikeBus! Автобусы LikeBus оснащены wi-fi, кондиционерами, ...</p>
                            </div>

                            <div className="news-list--item-action">
                                <Button className={ 'base-orange-btn write initial-width' } variant="contained"> {t('Подробнее')} </Button>
                            </div>
                        </div>

                        <div className="news-list--item">
                            <div className="news-list--item__thumbnail">
                                <img className='demo' src="/images/news-demo-thumbnail_1.png" alt="news title"/>
                            </div>

                            <div className="news-list--item--title">+190 новых автобусных рейсов от LikeBus</div>

                            <div className="news-list--item-content">
                                <p>К нам присоединился крутой перевозчик LikeBus! Автобусы LikeBus оснащены wi-fi, кондиционерами, ...</p>
                            </div>

                            <div className="news-list--item-action">
                                <Button onClick={ ()=>{
                                    navigate('/news-item');
                                } } className={ 'base-orange-btn write initial-width' } variant="contained"> {t('Подробнее')} </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
export default NewsList
