import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import BaseDialog from '../BaseDialog/BaseDialog';

const RegisterSuccess = props => {
    const { t } = useTranslation();

    return (
        <BaseDialog open={ props.open } onClose={ () => props.onClose(false) } headerText={ t('account_success') }>

            {/*body of dialog*/}
            <React.Fragment>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('account_success')}
                </Typography>
                <Typography id="modal-modal-description" sx={ { mt: 2 } }>
                    {t('confirm_you_email')}
                </Typography>
            </React.Fragment>
            {/*body of dialog*/}

        </BaseDialog>
    )
};

RegisterSuccess.propTypes = {
 open:PropTypes.bool.isRequired,
 onClose:PropTypes.func.isRequired,
};

export default RegisterSuccess;
