import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {formatDate, formatTime, getTypePlaceByValue} from "../../utils/responceFormValidatorHelper";

const ListOrders = props => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <div className="tab-results-show">
                <h3>{t('found_orders')}{`(${props.data.length})`}</h3>

                <div className="tab-results-head">
                    <div className="tab-results-head__cell">
                        <div className="label--cell">{t('trip_details')}</div>
                    </div>

                    <div className="tab-results-head__cell">
                        <div className="label--cell">{t('ticket_category')}</div>
                    </div>

                    <div className="tab-results-head__cell">
                        <div className="label--cell">{t('contact_details')}</div>
                    </div>

                    <div className="tab-results-head__cell">
                        <div className="label--cell">{t('price')}</div>
                    </div>
                </div>
            {props.data.map(e=>{
                // console.log(e)
                return (
                    <div className="tab-results-row">
                        <div className="tab-results__cell">
                            <div className="title-value--row">
                                <div className="title">{t('trip')}:</div>
                                <div className="value">{e.trip.number+' '+e.trip.route.name}</div>
                            </div>

                            <div className="title-value--row">
                                <div className="title">{t('place')}:</div>
                                <div className="value">{e.place}</div>
                            </div>

                            <div className="title-value--row">
                                <div className="title">{t('departure_time')}:</div>
                                <div className="value"> {formatDate(e.station_from.depart_at)} {t('in')} {formatTime(e.station_from.depart_at)}</div>
                            </div>
                        </div>

                        <div className="tab-results__cell">
                            <div className="title-value--row">
                                <div className="title">{t('category')}:</div>
                                {/*<div className="value">Полный</div>*/}
                                <div className="value">{getTypePlaceByValue(e.ticket_type)}</div>
                            </div>
                        </div>

                        <div className="tab-results__cell">
                            <div className="title-value--row">
                                <div className="title">{t('full_name')}:</div>
                                <div className="value">{e.first_name + ' '+e.last_name}</div>
                            </div>

                            {/*нет в корзине*/}
                            {/*<div className="title-value--row">*/}
                            {/*    <div className="title">{t('email')}:</div>*/}
                            {/*    <div className="value">{e.owner_email}</div>*/}
                            {/*</div>*/}

                            <div className="title-value--row">
                                <div className="title">{t('phone')}:</div>
                                <div className="value">{e.tel}</div>
                            </div>
                        </div>

                        <div className="tab-results__cell">
                            <div className="price">{ (e.price/100).toFixed(2) } $</div>
                        </div>
                    </div>

                )
            })}
            </div>
        </React.Fragment>
    );
};

ListOrders.defaultProps={
    data:[]
}

ListOrders.propTypes = {
    data:PropTypes.array.isRequired
};

export default ListOrders;
