import React, {useState, useEffect} from "react";
import TopMenu from "../components/TopMenu";
import {useTranslation} from "react-i18next";
import BreadCrumbs from "../components/Ui/BreadCrumbs/BreadCrumbs";

const Agreement = () => {
    const {t, i18n, ready} = useTranslation();
    const breadcrumbsData = [
        {text: t('Заказ билетов'), href: '/user-account/my-orders?'},
        {text: t('Договор оферты'), href: false}
    ]
    const [showAgreement, setShowAgreement] = useState(false)

    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={breadcrumbsData}/>
                </div>
            </div>

            <div className="page-container">
                <div className="user-page-content">
                    <div className="user-page-content__title">{t('ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ')}</div>

                    {showAgreement && <div className='fade'>
                        <div className="agreement-text">
                            <p>Общество с ограниченной ответственностью «И-Тревелс» ЕГРПОУ 35389109, адрес: 03056,
                                Украина, Киев, улица Полевая 21, руководствуясь ст. 633 Гражданского кодекса Украины,
                                предлагает неограниченному кругу лиц (далее Пользователь) заключить Договор публичной
                                оферты (далее Договор), с целью поиска и/или приобретения билетов с помощью сайта, и/или
                                дополнительных сервисов Superpilet. Пользователь может действовать в рамках Договора от
                                своего имени и в свою пользу и/или от имени третьей стороны и в ее пользу, в таком
                                случае пользователь действует исключительно в пределах полномочий предоставленных
                                третьей стороной.
                            </p>

                            <div className="custom-list--item">
                                <div className="custom-list--item__title"><span>1.</span>{t('ПРЕДМЕТ ДОГОВОРА.')}</div>

                                <div className="gray-block">
                                    <ul>
                                        <li><span>1.1</span>
                                            <p>По договору, Superpilet предоставляет услуги по поиску соответствующего
                                                Поставщика, в соответствии с датой, временем и направлением избранным
                                                Пользователем, формирование стыковок различных Поставщиков, заключение
                                                договора перевозки (путем продажи/приобретения Билета), оформление
                                                билетов различных Поставщиков одним заказом, предварительной продажи
                                                билетов, бронирование мест, заказ билета по телефону, переоформления
                                                билета, поиск оптимального расписания по запросу Пользователя, сервисное
                                                обслуживание Пользователя, в том числе и круглосуточную поддержку
                                                Пользователю Центром сервисного обслуживания, дополнительные услуги по
                                                запросам Пользователя, а также предварительной оплаты Билета средствами
                                                полученными от Пользователя (далее Услуги).
                                            </p>
                                        </li>

                                        <li><span>1.2</span>
                                            <p>За предоставляемые Услуги, Superpilet получает вознаграждение, состоящее
                                                из суммы средств, входящих в стоимость Билета и определенные, как
                                                Агентское вознаграждение (оплачивается Поставщиком) и Сервисный сбор
                                                (оплачивается пользователем).
                                            </p>
                                        </li>

                                        <li><span>1.3</span>
                                            <p>Superpilet действуя как агент от имени, за счет в интересах Поставщика, с
                                                помощью сайта, продает автобусную пассажирскую перевозку (Билет),
                                                специальные предложения, скидки и предоставляет информацию о Поставщике.
                                                Каждое приобретение Билета отдельно взятого Поставщика, подчиняется
                                                правилам и условиям именно этого Поставщика и является неотъемлемой
                                                частью Договора. В случае приобретения нескольких билетов одним заказом,
                                                правила и условия того или иного Поставщика могут отличаться.
                                            </p>
                                        </li>

                                        <li><span>1.4</span>
                                            <p> Несоблюдение правил и условий Поставщика и/или Superpilet может привести
                                                к отказу в доступе к Сайту, отмене заказов и и/или приобретенных услуг,
                                                в том и числе и к возможности воспользоваться билетом, без возврата
                                                затраченных на его приобретение средств.
                                                Приобретение Билета Пользователем на льготных условиях (бесплатная
                                                перевозка и т.п.) в соответствии с законодательством Украины,
                                                осуществляется в кассе/офисе соответствующего Поставщика, или с
                                                разрешения соответствующего Поставщика.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="custom-list--item">
                                <div className="custom-list--item__title"><span>2.</span> ПРАВА И ОБЯЗАННОСТИ СТОРОН.
                                </div>

                                <div className="gray-block">
                                    <ul>
                                        <li><span>2.1</span>
                                            <p>По договору, Superpilet предоставляет услуги по поиску соответствующего
                                                Поставщика, в соответствии с датой, временем и направлением избранным
                                                Пользователем, формирование стыковок различных Поставщиков, заключение
                                                договора перевозки (путем продажи/приобретения Билета), оформление
                                                билетов различных Поставщиков одним заказом, предварительной продажи
                                                билетов, бронирование мест, заказ билета по телефону, переоформления
                                                билета, поиск оптимального расписания по запросу Пользователя, сервисное
                                                обслуживание Пользователя, в том числе и круглосуточную поддержку
                                                Пользователю Центром сервисного обслуживания, дополнительные услуги по
                                                запросам Пользователя, а также предварительной оплаты Билета средствами
                                                полученными от Пользователя (далее Услуги).
                                            </p>
                                        </li>

                                        <li><span>2.2</span>
                                            <p>За предоставляемые Услуги, Superpilet получает вознаграждение, состоящее
                                                из суммы средств, входящих в стоимость Билета и определенные, как
                                                Агентское вознаграждение (оплачивается Поставщиком) и Сервисный сбор
                                                (оплачивается пользователем).
                                            </p>
                                        </li>

                                        <li><span>2.3</span>
                                            <p>Superpilet действуя как агент от имени, за счет в интересах Поставщика, с
                                                помощью сайта, продает автобусную пассажирскую перевозку (Билет),
                                                специальные предложения, скидки и предоставляет информацию о Поставщике.
                                                Каждое приобретение Билета отдельно взятого Поставщика, подчиняется
                                                правилам и условиям именно этого Поставщика и является неотъемлемой
                                                частью Договора. В случае приобретения нескольких билетов одним заказом,
                                                правила и условия того или иного Поставщика могут отличаться.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <button onClick={() => setShowAgreement(!showAgreement)}
                            className={'base-orange-btn blue fix-width'}>
                        {showAgreement ? t('Свернуть') : t('Прочесть Условия')}</button>
                </div>
            </div>
        </div>
    )
}
export default Agreement





