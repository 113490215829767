import React, {useState} from 'react'
import moment from "moment";

const RoadMap = ({stations}) => {

    const [toggleShowFullRoadMap, setShowFullMap] = useState(false)

    if (!stations) {
        return
    }

    const innerStations = stations.slice(1, stations.length - 1)

    return (
        <div className='road-map-wrap'>
            <div className="road-map">
                <ul className='road-map-list'>
                    <li className='road-map-list__item'>
                        <div className="time--cell">{moment.utc(stations[0].depart_at).local().format('HH:mm')}</div>

                        <div className="circle--cell">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="6.5" fill="white" stroke="#E97D35"/>
                            </svg>
                        </div>

                        <div className="town--cell">
                            <div className="town">{stations[0].name}</div>
                            <div className="address">{stations[0].address}</div>
                        </div>
                    </li>

                    {toggleShowFullRoadMap && <div className='fade-div'>
                        {innerStations.map(station =>
                            <li key={station.id} className='road-map-list__item'>
                                <div className="time--cell">
                                    {moment.utc(station.arrival_at).local().format('HH:mm')}
                                </div>

                                <div className="circle--cell">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="6.5" fill="white" stroke="#E97D35"/>
                                    </svg>
                                </div>

                                <div className="town--cell">
                                    <div className="town">{station.name}</div>
                                    <div className="address">{station.address}</div>
                                </div>
                            </li>
                        )}
                    </div>}

                    <li className='road-map-list__item'>
                        <div
                            className="time--cell">{moment.utc(stations[stations.length - 1].arrival_at).local().format('HH:mm')}</div>

                        <div className="circle--cell">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7" cy="7" r="6.5" fill="white" stroke="#E97D35"/>
                            </svg>
                        </div>

                        <div className="town--cell">
                            <div className="town">{stations[stations.length - 1].name}</div>
                            <div className="address">{stations[stations.length - 1].address}</div>
                        </div>
                    </li>
                </ul>
            </div>

            {innerStations.length ?
                <div className="road-map-footer">
                    <button onClick={setShowFullMap.bind(null, !toggleShowFullRoadMap)}
                            className="show-full">Промежуточные пункты ({innerStations.length})
                    </button>
                </div>
                : null}
        </div>
    )
}

export default RoadMap
