import React from 'react';
//hook for works with form
function useFormField (initialValue= '',name,placeholder,error,callback=f=>f){
  const [ value, setValueL ] = React.useState(initialValue);
  const onChange = React.useCallback((e) => {
    setValueL(e.target.value)
    callback(e.target.value)
  }, []);
  const setValue=(val)=>{
    setValueL(val);
    callback(val);
  }

  return { value, onChange,setValue,name,error,placeholder  };
}
export default useFormField;
