import React, {useState, useContext} from 'react'

const cartContext = React.createContext(null);

function CartProvider({children}) {

    const [info, _setInfo] = useState({});
    const [open, setOpen] = useState(false);

    return (
        <cartContext.Provider value={{
            info, setInfo: v => _setInfo(v || {}),
            open, setOpen,
        }}>{children}</cartContext.Provider>
    )
}

export function useCartContext() {
    return useContext(cartContext);
}

export default CartProvider
