import React, {useState, useContext, Fragment, useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import {getData} from '../../api/baseAPI'
import CartDialog from './CartDialog';
import {useCartContext} from './CartProvider';
import LeftTime from './LeftTime';
import {useNavigate} from 'react-router-dom';

function Cart({}) {
    const cart = useCartContext()
    const {t, i18n, ready} = useTranslation()
    const navigate = useNavigate();

    useEffect(() => {
        getData('/cart-info')
            .then(({data}) => {
                cart.setInfo(data.info || {})
            })
            .catch(({message}) => console.log(message))
    }, [])

    function handleClick(e) {
        e.preventDefault()
        cart.setOpen(true)
    }

    return (
        <Fragment>
            <a className={cart.info.count_tickets ? 'nav-link cart-nav-link cart-nav-link-has-ticket' :
                'nav-link cart-nav-link'}
               href={'#'}
               onClick={handleClick} data-before={t('Корзина')}>
                <span className="cart-icon--wrap">
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_179_907)">
                            <path
                                d="M38.3253 6.07748C37.769 5.40283 36.9444 5.01618 36.0633 5.01618H13.4345C12.971 5.01618 12.5948 5.39232 12.5948 5.85582C12.5948 6.31933 12.971 6.69546 13.4345 6.69546H36.0628C36.4461 6.69546 36.7896 6.85542 37.0293 7.14594C37.2728 7.44149 37.3701 7.83111 37.2967 8.21021L33.941 24.9895C33.8109 25.589 33.3025 26.0076 32.7046 26.0076H14.0403L6.33435 2.80743C5.91784 1.61763 4.79019 0.818282 3.49878 0.818282C3.4984 0.818282 3.49794 0.818282 3.49748 0.818282C2.40213 0.856063 1.29132 0.855682 1.01923 0.837173C0.748444 0.778368 0.455793 0.85393 0.245939 1.06386C-0.0819798 1.39178 -0.0819798 1.92323 0.245939 2.25115C0.505837 2.51105 0.593587 2.5988 3.5265 2.49718C4.07646 2.49718 4.56808 2.84483 4.74487 3.34939L12.6376 27.1121C12.7518 27.4551 13.0726 27.6868 13.4345 27.6868H32.7045C34.0993 27.6868 35.2823 26.7241 35.585 25.3324L38.9448 8.53463C39.1145 7.65645 38.8891 6.76097 38.3253 6.07748Z"
                                fill="white"/>
                            <path
                                d="M32.327 32.3053H17.0453C16.5285 32.3053 16.0427 31.9492 15.8677 31.4496L14.2304 26.5796C14.0826 26.14 13.6052 25.9028 13.1669 26.0514C12.7273 26.1988 12.491 26.6753 12.6387 27.1149L14.2794 31.9945C14.6963 33.1847 15.8076 33.984 17.0452 33.984H32.3269C32.7904 33.984 33.1666 33.6083 33.1666 33.1448C33.1666 32.6812 32.7905 32.3053 32.327 32.3053Z"
                                fill="white"/>
                            <path
                                d="M17.6321 32.3047C16.0116 32.3047 14.6934 33.6234 14.6934 35.2435C14.6934 36.8636 16.0116 38.1822 17.6321 38.1822C19.2527 38.1822 20.5709 36.8636 20.5709 35.2435C20.5709 33.6234 19.2527 32.3047 17.6321 32.3047ZM17.6321 36.503C16.9378 36.503 16.3726 35.9378 16.3726 35.2435C16.3726 34.5491 16.9378 33.984 17.6321 33.984C18.3265 33.984 18.8916 34.5491 18.8916 35.2435C18.8916 35.9379 18.3265 36.503 17.6321 36.503Z"
                                fill="white"/>
                            <path
                                d="M32.3265 32.3047C30.7064 32.3047 29.3877 33.6234 29.3877 35.2435C29.3877 36.8636 30.7064 38.1822 32.3265 38.1822C33.9466 38.1822 35.2653 36.8636 35.2653 35.2435C35.2653 33.6234 33.9466 32.3047 32.3265 32.3047ZM32.3265 36.503C31.6321 36.503 31.067 35.9378 31.067 35.2435C31.067 34.5491 31.6321 33.984 32.3265 33.984C33.0209 33.984 33.586 34.5491 33.586 35.2435C33.586 35.9379 33.0209 36.503 32.3265 36.503Z"
                                fill="white"/>
                        </g>

                        <defs>
                            <clipPath id="clip0_179_907">
                                <rect width="39" height="39" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    {/* Здесь количество билетов в корзине */}
                    {cart.info.count_tickets ?
                        <span className="ticket-in-cart fade">{cart.info.count_tickets}</span> : null}
                </span>

                <span className="time-count">
                    <LeftTime
                        key={cart.info.time_left}
                        time={cart.info.time_left}
                        onExpired={cart.setInfo.bind(null, {})}
                    />
                </span>
            </a>

            <CartDialog
                open={cart.open}
                onClose={cart.setOpen.bind(null, false)}
                onSuccess={a => {
                    if (1) {
                        cart.setOpen(false)
                        navigate('/payment');
                    }

                    console.log('Cart On SUCCESS!', a)
                }}
            />
        </Fragment>
    )
}

export default Cart
