import React from "react";


const ConditionsTab = () => {
    return (
        <div className='fade tab--content--item'>
            456
        </div>
    )
}
export default ConditionsTab
