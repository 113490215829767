import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Logo from '../../Ui/Logo';
import CircularProgress from '@mui/material/CircularProgress';

const AwaitDialog = props => {
    return (
        <React.Fragment>
            <Dialog
                open={ props.open }
                onClose={ f => props.onClose(false) }
                className='await--dialog'
                scroll={ 'paper' }>
                <DialogTitle className='await--dialog__head'>
                    <Logo/>
                </DialogTitle>

                <DialogContent dividers={ scroll === 'paper' }>
                    <DialogContentText>
                        <div className="await--dialog__tabs-list">
                            <CircularProgress  size={70}/>
                            <br/>
                            <p>AWAIT...</p>
                        </div>
                        <div className="await--dialog-action">
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

AwaitDialog.propTypes = {
 open:PropTypes.bool.isRequired,
 onClose:PropTypes.func.isRequired
};

export default AwaitDialog;
