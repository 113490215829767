import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../elements/Select';
import clsx from 'clsx';
import {useTranslation} from "react-i18next";

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return  (
        <div  className={ clsx('filterOption',data.type) } ref={ innerRef } { ...innerProps }>
            {data.label} <span className="emoji" role="img">{data.emoji}</span>
        </div>
    )
};

const SelectCountries = props => {
    const { t } = useTranslation();
    const [ data,setData ]=useState([])
    const [ selected,setSelected ]=useState(null)
    const [ key,setKey ]=useState('key')
    useEffect(()=>{
        fetch('/json/countries/countries.json')
            .then((response) => {return response.json();}).then((data) => {
                setData(data)
           const index= data.findIndex(e=>e.code===props.value)
            if(index>-1){
                setSelected(data[ index ])
                setKey(Math.random());
            }
            // setSelected(data[ 22 ])

            });
    },[])
    useEffect(()=>{
        if(props.value){
            const index= data.findIndex(e=>e.code===props.value)
            if(index>-1){
                setSelected(data[ index ])
                setKey(Math.random());
            }
        }
    },[props.value,data])

    return (
        <React.Fragment>
            <Select
                key={ key }
                defaultValue={ selected }
                components={ { Option: CustomOption, DropdownIndicator: () => null, IndicatorSeparator: () => null } }
                onChange={ (data)=>{
                    props.onSelect(data.code)
                } }

                options={ data }
                // isLoading={ isLoading }
                placeholder={ t('select_you_country') }
                indicatorsContainer={ false }
                // loadingMessage={()=>{return 'Some me'}}
                // cacheOptions={true}
                // isClearable={false}
                // loadOptions={loadOptions}
                // defaultOptions={true}
            />
        </React.Fragment>
    );
};

SelectCountries.propTypes = {
 value:PropTypes.string.isRequired,
};

export default SelectCountries;
