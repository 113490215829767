import React, {useState} from 'react'
import {Scrollbars} from 'react-custom-scrollbars';

const Questions = props => {
    let [question1, changeClasses1] = useState(false);
    let [question2, changeClasses2] = useState(false);
    let [question3, changeClasses3] = useState(false);
    let [question4, changeClasses4] = useState(false);
    let [question5, changeClasses5] = useState(false);
    let [question6, changeClasses6] = useState(false);
    let [fakeState, fakeChange] = useState({});


    return (
        <React.Fragment>
            <section className={'questions'}>
                <section className={'container '}>
                    <h3>Частозадаваемые вопросы</h3>
                    <div className={'questions-wrapper'}>
                        <div className={'half-questions-wrapper'}>
                            <div className={`question-block ${question1 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses1(question1 = !question1);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)

                                }}>
                                    <p className={'question-text'}><strong>01.</strong> Как купить билет на автобус?</p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars universal={true} className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className={`question-block ${question2 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses2(question2 = !question2);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)
                                }}>
                                    <p className={'question-text'}><strong>02.</strong> Сколько у меня времени на оплату
                                        билета?</p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>5. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className={`question-block ${question3 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses3(question3 = !question3);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)
                                }}>
                                    <p className={'question-text'}><strong>03.</strong> Как узнать расписание автобусов?
                                    </p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>5. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                        <div className={'half-questions-wrapper'}>
                            <div className={`question-block ${question4 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses4(question4 = !question4);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)
                                }}>
                                    <p className={'question-text'}><strong>04.</strong> Какую фамилию и имя указывать
                                        при оформлении заказа?</p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>5. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className={`question-block ${question5 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses5(question5 = !question5);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)
                                }}>
                                    <p className={'question-text'}><strong>05.</strong> Мне обяхательно печатать
                                        электронный билет?</p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>5. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className={`question-block ${question6 ? 'active' : ''}`}>
                                <div className={'question-wrapper'} onClick={() => {
                                    changeClasses6(question6 = !question6);
                                    setTimeout(function (){
                                        fakeChange({})
                                    },500)
                                }}>
                                    <p className={'question-text'}><strong>06.</strong> Могу ли я получить
                                        дополнительную информацию и услуги на автовокзале?</p>
                                    <img className={'icon-plus'} src={'images/questions-icon-plus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'21'}/>
                                    <img className={'icon-minus'} src={'images/questions-icon-minus.svg'}
                                         alt="Question icon" width={'21'}
                                         height={'4'}/>
                                </div>
                                <div className={'answer-block'}>
                                    <Scrollbars className={'answer-wrapper'}>
                                        <p className={'answer-text answer-caption'}><strong>Для покупки автобусного
                                            билета необходимо сделать 3 простых шага:</strong></p>
                                        <p className={'answer-text'}>1. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>2. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>3. Ввести пункт отправления, прибытия, желаемую
                                            дату и количество мест. Нажмите кнопку «Найти».</p>
                                        <p className={'answer-text'}>4. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                        <p className={'answer-text'}>5. Выбрать подходящий рейс нажав на кнопку
                                            «Оформить», а затем подтвердите выбор нажав «Оформить»;</p>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </React.Fragment>
    );
};

Questions.propTypes = {};

export default Questions;
