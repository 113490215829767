import React, {useEffect, useState, useRef} from 'react'

const CarrierTab = () => {
    return (
        <div className='fade tab--content--item'>
            124
        </div>
    )
}
export default CarrierTab
