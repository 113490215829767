import React, {useEffect, useState} from "react"
import {useTranslation} from 'react-i18next';
import {sbConfig} from "../app";

// https://react.i18next.com/latest/using-with-hooks
// https://codesandbox.io/s/v9cq3

function LangSelector({}) {

    const {t, i18n, ready} = useTranslation();

    const [lang, setLang] = useState(document.documentElement.getAttribute('lang'))
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (lang !== i18n.language) {
            setLang(i18n.language)
        }
    }, [])

    useEffect(() => {
        axios.get(`/api/set-locale/${lang}`)
            .then(() => {
                document.documentElement.setAttribute('lang', lang)
                i18n.language !== lang && i18n.changeLanguage(lang)
            })
    }, [lang])

    function handleSetOpen(e) {
        e.preventDefault()
        setOpen(!open)
    }

    function handleSetLang(k, e) {
        e && e.preventDefault()
        setOpen(false)

        if (k !== lang) {
            setLang(k)
        }
    }

    return (
        <li className='nav-item dropdown'>
            <a className={`nav-link dropdown-toggle${open ? ' active' : ''}`}
               href='#'
               onClick={handleSetOpen}
            >{lang}</a>
            <div className={`dropdown-items${open ? ' active' : ''}`}>
                {sbConfig('languages', []).filter(k => lang !== k).map(k =>
                    <a key={k} className='dropdown-item' href='#'
                       onClick={handleSetLang.bind(null, k)}>{k.toUpperCase()}</a>
                )}
            </div>
        </li>
    )
}

export default LangSelector
