import React, { useEffect, useState } from 'react';
import TopMenu from '../../components/TopMenu';

import useRequest from '../../hooks/useRequest';
import Pay from '../Payment/Pay/Pay';
import { getCartData, getStationByAlias } from '../../api/listAPI';

const Payment  = () => {

    const [ data, loadingdata, reUpdatedata ] = useRequest(getCartData)
    const [ tickets,setTickets ]=useState([])
    const [ total,setTotal ]=useState(0)

    useEffect(()=>{
        if(data){
            setTickets(data.tickets)
            setTotal(parseInt(data.total))
            // debugger
        }
    },[ data ])
    useEffect(() => {
        reUpdatedata()
    }, [])

    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className="page-container">
                {/*{TabContentRender()}*/}
                <Pay data={ tickets } loading={ loadingdata } total={ total }  />
            </div>
        </div>
    )
}
export default Payment
