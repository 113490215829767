import { ORDER } from '../ActionTypes';

const initialSetting = {
     // total:0,
     // total:15,
     order:false
     // order:{
     //     payment_methods:[],
     // },
};
const orderReducer = (state = initialSetting, action) => {
    switch (action.type) {
      // case ORDER.SET_PREV_TOTAL:
      //   return { ...state,total: action.payload }
      case ORDER.SET_ORDER:
          return { ...state,order: action.payload }
      case ORDER.CLEAR_ORDER:
          return { ...state,...initialSetting }
        default:
            return state;
    }
}
export default orderReducer;
// export const setPrevTotal=(data)=> ({ type:ORDER.SET_PREV_TOTAL,payload: data });
export const setOrder=(data)=> ({ type:ORDER.SET_ORDER,payload: data });
export const clearOrder=()=> ({ type:ORDER.CLEAR_ORDER });
