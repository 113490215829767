import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {ValidatorComponent, ValidatorForm} from 'react-material-ui-form-validator';
const style = {

    fontSize: '12px',
    color: '#d20e0e',
    position: 'absolute',
    marginTop: '-14px',
    paddingLeft:'31px'

};
class CheckboxValidatorElement extends ValidatorComponent {

    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, value, ...rest } = this.props;
       // debugger
        return (
            <div>
                <Checkbox
                    {...rest}
                    ref={(r) => { this.input = r; }}

                />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;
        if (isValid) {
            return null;
        }
        return (
            <div style={style}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default CheckboxValidatorElement;
