import React, {useState} from 'react'
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CartForm from "./CartForm";
import Logo from "../Ui/Logo";
import {useTranslation} from "react-i18next";

function CartDialog({open, onClose, ...rest}){

    const {t, i18n, ready} = useTranslation()

    return (
        <Dialog
            className='ticket-registration--dialog'
            open={open}
            onClose={onClose}
            scroll={'paper'}>
            <DialogTitle className='ticket-registration--dialog__head'>
                <Logo/>
                <h3>{t('ticketing')}</h3>
            </DialogTitle>

            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    tabIndex={-1}>
                    <CartForm {...rest}/>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default CartDialog
