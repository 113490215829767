import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import useFormField from '../../hooks/useFormField';
import { storeContainer } from '../../api/storeContainer';
import { useNavigate } from 'react-router-dom';
import { isMinLengthString, isNotValidEmail } from '../../utils/responceFormValidatorHelper';

const LogIn = (props) => {
    const navigate = useNavigate();
    const email = useFormField('')
    const [ errorEmail,setErrorEmail ] = useState(false);

    const password = useFormField('')
    const [ errorPassword,setErrorPassword ] = useState(false);
    useEffect(()=>{
        localStorage.removeItem('token')
    },[])

    const validate=()=>{
        let result =true;
       if(isNotValidEmail(email.value)){
           setErrorEmail(isNotValidEmail(email.value));
           result= false;
       }else{
           setErrorEmail(false)
       }

        if(isMinLengthString(password.value,6)){
            setErrorPassword(isMinLengthString(password.value,6));
            result= false;
        }else{
            setErrorPassword(false)
        }
       return result;
    }

   function onSocialClick(param, e) {
        window.location.assign( `/api/auth/${ param }/login` );
    }
    return (
        <div className='fade log_in'>

            <div className='contact-data-form '>
                <form>
                    <Box
                    component="form"
                    noValidate
                    autoComplete="off">
                        <div className="contact-data-form--row">
                            <TextField fullWidth placeholder={ 'Email' } { ...email } name={ 'email' } error={ errorEmail } helperText={ errorEmail } />
                        </div>

                        <div className="contact-data-form--row">
                            <TextField fullWidth placeholder={ 'Пароль' } { ...password } type={ 'password' } name={ 'password' } error={ errorPassword } helperText={ errorPassword }/>
                        </div>

                        <div className="checkbox--row">
                            <div className="checkbox--wrap">
                                <Checkbox defaultChecked />
                            </div>
                            <p>Запомнить меня</p>
                        </div>
                        <div className="contact-data-form--row">
                            <button type={ 'submit' } onClick={ (event)=>{
                            event.preventDefault()
                            if(validate()){
                                props.login(email.value,password.value).then(e=>{
                                    if(e){
                                        navigate('/user-account')
                                    }

                                })
                            }

                        } } className="base-orange-btn">Войти</button>
                        </div>
                    </Box>
                </form>

            </div>

            <h4>Или</h4>
            <ul className="networks-link--list">
                <li className='networks-link'>
                    <button onClick={ onSocialClick.bind(null,'facebook') } className='facebook'>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.3233 30V16.3166H21.9144L22.6032 10.9823H17.3233V7.5772C17.3233 6.0333 17.7503 4.98115 19.9667 4.98115L22.789 4.97999V0.208845C22.301 0.145418 20.6256 0 18.6756 0C14.6037 0 11.816 2.48545 11.816 7.0489V10.9823H7.21094V16.3166H11.816V30H17.3233Z" fill="white"/>
                        </svg>
                        войти через facebook</button>
                </li>

                <li className='networks-link'>
                    <button onClick={ onSocialClick.bind(null,'google') } className='google'>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 15.0001C30 14.0576 29.9116 13.1143 29.7365 12.1985C29.6575 11.784 29.2945 11.4844 28.8731 11.4844H15.0586C14.5728 11.4844 14.1797 11.8775 14.1797 12.3633V17.6367C14.1797 18.1225 14.5728 18.5156 15.0586 18.5156H22.1465C21.7049 19.4096 21.0963 20.1854 20.3816 20.838L25.3492 25.8056C28.2074 23.0824 30 19.2504 30 15.0001Z" fill="white"/>
                            <path d="M14.9997 7.03125C16.7241 7.03125 18.3643 7.57887 19.7427 8.61568C20.0912 8.87748 20.583 8.84572 20.892 8.53412L24.6428 4.7833C24.8171 4.60904 24.9106 4.36957 24.8994 4.12324C24.8883 3.87691 24.775 3.64687 24.5861 3.48809C21.9022 1.23855 18.4973 0 14.9997 0C9.85232 0 5.30492 2.61645 2.6167 6.58898L7.77576 11.748C9.00658 8.97563 11.7758 7.03125 14.9997 7.03125Z" fill="white"/>
                            <path d="M14.9997 30C18.355 30 21.4446 28.875 23.9412 27.0008L18.8804 21.94C17.7237 22.5913 16.4016 22.9688 14.9997 22.9688C11.7758 22.9688 9.00658 21.0244 7.77576 18.252L2.6167 23.411C5.30492 27.3836 9.85232 30 14.9997 30Z" fill="white"/>
                            <path d="M7.15922 16.2659C7.09189 15.8511 7.03125 15.4334 7.03125 15C7.03125 14.5666 7.09189 14.1489 7.15922 13.7341L1.64414 8.21899C0.600703 10.2569 0 12.5576 0 15C0 17.4424 0.600703 19.7431 1.6442 21.7809L7.15922 16.2659Z" fill="white"/>
                        </svg>

                        войти через google</button>
                </li>
            </ul>

            <a href="/forgot-password" className='forgot-password'>Забыли пароль</a>
        </div>
    )
}
export default  storeContainer(LogIn)
