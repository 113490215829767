import React from 'react';
import PropTypes from 'prop-types';

const ToastInfo = props => {
    return (
        <div>
            <ul>
                {props.textArray.map((e,i)=><li key={ i }>{e}</li>)}
            </ul>
        </div>
    );
};

ToastInfo.propTypes = {
    textArray:PropTypes.array.isRequired,
};

export default ToastInfo;
