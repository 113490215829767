import React  from 'react'
import Box from '@mui/material/Box';
import { TextValidator } from 'react-material-ui-form-validator';
import Options from '../Options';
import { sbConfig } from '../../../app';
import GetTicketSms from '../GetTicketSms';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PhoneValidatorElement from '../../../validators/PhoneValidatorElement';
import { compose } from 'redux';
import { ValidatorsContainer } from '../../../validators/ValidatorsContainer';
import {formatDate, formatTime} from "../../../utils/responceFormValidatorHelper";

const ContactDataForm = ({ ticket, onClickDelete, onChangeTicket, processing }) => {

    const { t } = useTranslation();

    function handleChangeInput(e) {
        onChangeTicket(ticket, e.target.name, e.target.value)
    }

    function handleChangeCbSms(e) {
        onChangeTicket(ticket, 'sms', e.target.checked)
    }
    function handleChangePhone(value) {
        onChangeTicket(ticket, 'tel', value)
    }

    return (
        <div className="ticket-registration--dialog__tab-item">
            <div className="ticket-registration--tab">
                <div className="ticket-registration--tab_col">
                    <div className="ticket-registration--tab_col--head">
                        <div className="tab-head--label">{t('trip_details')}</div>
                    </div>

                    <div className="ticket-registration--tab_col--content">
                        <div className="details">
                            <div className="details--row">
                                <div className="label">{t('trip')}:</div>
                                <div className="value">№{ticket.trip.number} {ticket.trip.route.name}</div>
                            </div>

                            <div className="details--row">
                                <div className="label">{t('place')}:</div>
                                <div className="value">{ticket.place}</div>
                            </div>

                            <div className="details--row">
                                <div className="label">{t('departure_time')}:</div>
                                <div className="value">
                                    {formatDate(ticket.station_from.depart_at)} {t('in')} {formatTime(ticket.station_from.depart_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ticket-registration--tab_col">
                    <div className="ticket-registration--tab_col--head">
                        <div className="tab-head--label">{t('ticket_category')}</div>
                    </div>

                    <div className="ticket-registration--tab_col--content">
                        <Options
                            list={ sbConfig('ticket_types', null) }
                            name={ 'ticket_type' }
                            value={ ticket.ticket_type || '0' }
                            onChange={ handleChangeInput }
                        />
                    </div>
                </div>

                <div className="ticket-registration--tab_col">
                    <div className="ticket-registration--tab_col--head">
                        <div className="tab-head--label">{t('contact_details')}</div>
                    </div>

                    <div className="ticket-registration--tab_col--content">
                        <div className='contact-data-form'>
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off">

                                <div className="contact-data-form--row">
                                    <div className="contact-data-form--label">{t('name')}:</div>

                                    <TextValidator
                                        fullWidth
                                        placeholder={ t('name') }
                                        name={ 'first_name' }
                                        value={ ticket.first_name || '' }
                                        onChange={ handleChangeInput }
                                        validators={ [ 'required' ] }
                                        errorMessages={ [ 'this field is required' ] }
                                    />
                                </div>

                                <div className="contact-data-form--row">
                                    <div className="contact-data-form--label">{t('surname')}:</div>

                                    <TextValidator
                                        fullWidth
                                        placeholder={ t('surname') }
                                        name={ 'last_name' }
                                        value={ ticket.last_name || '' }
                                        onChange={ handleChangeInput }
                                        validators={ [ 'required' ] }
                                        errorMessages={ [ 'this field is required' ] }
                                    />
                                </div>

                                <div className="contact-data-form--row">
                                    <div className="contact-data-form--label">{t('phone_number')}:</div>

                                    <PhoneValidatorElement
                                        value={ ticket.tel  }
                                        onChange={ handleChangePhone }
                                        country={ 'ee' }
                                        validators={ [ 'required','minPhone' ] }
                                        errorMessages={ [ t('required_field'),t('required_field') ] }
                                    />

                                </div>
                            </Box>
                        </div>
                    </div>
                </div>

                <div className="ticket-registration--tab_col">
                    <div className="ticket-registration--tab_col--head">
                        <div className="tab-head--label">{t('price')}</div>
                    </div>

                    <div className="ticket-registration--tab_col--content">
                        <div className="price">{((ticket.price) / 100).toFixed(2)} €</div>
                    </div>
                </div>

                <div className="ticket-registration--tab_col">
                    <div className="ticket-registration--tab_col--head">
                        <div className="tab-head--label">{t('delete')} {t('ticket')}</div>
                    </div>

                    <div className="ticket-registration--tab_col--content">
                        <button
                            className="base-white-btn"
                            onClick={ onClickDelete }
                            disabled={ processing }
                        >
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.41392 4.99992L9.24292 7.82792C9.43056 8.01556 9.53598 8.27006 9.53598 8.53542C9.53598 8.80079 9.43056 9.05528 9.24292 9.24292C9.05528 9.43056 8.80079 9.53598 8.53542 9.53598C8.27006 9.53598 8.01556 9.43056 7.82792 9.24292L4.99992 6.41392L2.17192 9.24292C1.98428 9.43056 1.72979 9.53598 1.46442 9.53598C1.19906 9.53598 0.944564 9.43056 0.756923 9.24292C0.569283 9.05528 0.463867 8.80079 0.463867 8.53542C0.463867 8.27006 0.569283 8.01556 0.756923 7.82792L3.58592 4.99992L0.756923 2.17192C0.569283 1.98428 0.463867 1.72979 0.463867 1.46442C0.463867 1.19906 0.569283 0.944564 0.756923 0.756923C0.944564 0.569283 1.19906 0.463867 1.46442 0.463867C1.72979 0.463867 1.98428 0.569283 2.17192 0.756923L4.99992 3.58592L7.82792 0.756923C8.01556 0.569283 8.27006 0.463867 8.53542 0.463867C8.80079 0.463867 9.05528 0.569283 9.24292 0.756923C9.43056 0.944564 9.53598 1.19906 9.53598 1.46442C9.53598 1.72979 9.43056 1.98428 9.24292 2.17192L6.41392 4.99992Z"
                                    fill="#282828"/>
                            </svg>
                            {t('delete')}
                        </button>
                    </div>
                </div>
            </div>

            <GetTicketSms
                onChange={ handleChangeCbSms }
                checked={ ticket.sms }
                disabled={ processing }
            />
        </div>
    )
}
export default compose(ValidatorsContainer)( ContactDataForm)
