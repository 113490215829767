import React from 'react';
import AuthService from "./AuthService";
import { useToasts } from 'react-toast-notifications'
import {connect} from "react-redux";
import {removeUser, setAccessToken, setIsAuth, setUser} from "../redux/reducers/authReducer";
import {firstEngineError} from "../utils/responceFormValidatorHelper";

export const  storeContainer= (Component) => {
  // class wrapStoreContainer extends React.PureComponent {
     function wrapStoreContainer(props){
         const { addToast } = useToasts()
      async function  login(email,password){
          try {
              const response= await AuthService.login(email,password)
              localStorage.setItem('token',response.data.accessToken)
               props.setAccessToken(response.data.accessToken)
               return response.data;
          }catch (e){
              // todo stop here sam
              if(firstEngineError(e.response.data)){
                  addToast(firstEngineError(e.response.data), { appearance: 'error', autoDismiss: true });
              }
              console.log(firstEngineError(e.response.data))
              return  false
          }
      }

         async function  register(name,email,password,password_confirmation,lastName,phone){
             try {
                 const response= await AuthService.register(name,email,password,password_confirmation,lastName,phone)
                 // localStorage.setItem('token',response.data.accessToken)
                 // props.setAccessToken(response.data.accessToken)
                 return response.data;
             }catch (e){
                 // todo stop here sam
                 if(firstEngineError(e.response.data)){
                     addToast(firstEngineError(e.response.data), { appearance: 'error', autoDismiss: true });
                 }
                 console.log(firstEngineError(e.response.data))
                 return  false
             }
         }

         // async register(name,email,password,password_confirmation,lastName,phone){
         //     try {
         //         const response= await AuthService.register(name,email,password,password_confirmation,lastName,phone)
         //         return response;
         //     }catch (e){
         //         console.log(e.response.data)
         //     }
         //
         // }
         //

      return <Component
        { ...props }
        login={ login }
        register={ register }
      />

  }
    const mapDispatchToProps = {
        setUser,
        setIsAuth,
        setAccessToken,
        removeUser
    }
    // const mapStateToProps = (state) => {
    //
    //     return {
    //         data: state.routeSearch.data,
    //     };
    // }
    return connect(null, mapDispatchToProps)(wrapStoreContainer);
}
