import { useEffect, useState } from 'react';

export default function useKeyPress() {

    const [ pressedKey,setPressKey ]=useState(null);
    const pressListener=(e)=>{
        setPressKey(e.key)
    }
    useEffect(() => {
        window.addEventListener('keypress', pressListener);
        return ()=>{
            window.removeEventListener('keypress',pressListener)
        }
    }, []);

    return [ pressedKey ]
}
