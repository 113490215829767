require('./bootstrap');
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import * as serviceWorker from './serviceWorker'
import {compose, createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './redux/reducers'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';
import SearchResults from './pages/SearchResults'
import i18n from './translate/i18n'; // HEEDED IMPORT TO INIT! DONT MOVE NOW
import {ToastProvider} from 'react-toast-notifications'

import CartProvider from './components/Cart/CartProvider'
import TicketView from './pages/TicketView'
import Authorization from './pages/Authorization';
import ForgotPass from './pages/Authorization/ForgotPass'
import {appContainer} from './appContainer';
import Autologin from './pages/Autologin';
// import Account from './pages/Account/Account';

import NewsList from './pages/News/NewsList';
import NewsItem from './pages/News/NewsItem';
import Support from './pages/Support/Support';
import UserAccount from './pages/Account/UserAccount';
import Success from './pages/Payment/Status/Success/Success';
import Error from './pages/Payment/Status/Error/Error';
import Agreement from "./pages/Agreement";
import TicketReturn from "./pages/TicketReturn";
import Payment from "./pages/Payment/Payment";

let preStore = null;

if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    preStore = createStore(rootReducer,
        compose(
            applyMiddleware(
                thunk,
                // forbiddenWordsMiddleware,
                //saga
            ), window.__REDUX_DEVTOOLS_EXTENSION__())
    );
} else {
    preStore = createStore(rootReducer,
        compose(
            applyMiddleware(
                thunk,
                // saga
            )
        )
    );
}
export const store = preStore;

const App = appContainer((props) => {
    return <CartProvider>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path={'/auto-login/:jwt'} element={<Autologin/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path={'/search-results'} element={<SearchResults/>}/>
            <Route path={'/search-results/:from'} element={<SearchResults/>}/>
            <Route path={'/search-results/:from/:to'} element={<SearchResults/>}/>
            <Route path={'/search-results/:from/:to/:dt'}
                   element={<SearchResults/>}/>
            <Route path={'/search-results/:from/:to/:dt/:dtBack'}
                   element={<SearchResults/>}/>
            <Route path={'/search-results/tickets'} element={<TicketView/>}/>
            <Route path={'/ticket-view'} element={<TicketView/>}/>

            {/*<Route path={'/cart'} element={<Cart/>}/>*/}
            <Route path={'/payment'} element={<Payment/>}/>

            <Route path={'/authorization'} element={<Authorization/>}/>
            {/*//rm it */}
            {/*<Route path={'/account'} element={<Account/>}/>*/}
            <Route path={'/forgot-password'} element={<ForgotPass/>}/>

            <Route path={'/news'} element={<NewsList/>}/>
            <Route path={'/news-list'} element={<NewsList/>}/>
            <Route path={'/news-item'} element={<NewsItem/>}/>

            {/*account кабинет*/}
            <Route path={'/user-account'} element={<UserAccount/>}>
                <Route path=":part" element={<UserAccount/>}/>
                {/*<Route path="*" element={<NotFound />} />*/}
            </Route>

            <Route path={'/support'} element={<Support/>}/>
            <Route path={'/agreement'} element={<Agreement/>}/>
            <Route path={'/ticket-return'} element={<TicketReturn/>}/>
            <Route path={'/payment-success'} element={<Success/>}>
                <Route path=":orderId" element={<Success/>}/>
            </Route>

            <Route path={'/payment-error'} element={<Error/>}/>

            {/*moved to  /cart need delete after logic todo  */}
            {/*<Route path={ '/checkout-payments' } element={ <CheckoutPayment/> }/>*/}

            {/*moved  to  /account  public/resources/js/front/pages/Account/Account.js  */}
            {/*<Route path={ '/user-contacts-data' } element={ <UserContactsData /> }/>*/}

            {/*moved  to  /support     pages/Support/Support' */}
            {/*<Route path={ '/user-feedback-form' } element={ <UserFeedBackForm /> }/>*/}

        </Routes>
    </CartProvider>
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </ToastProvider>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();

export const getStore = () => store

export function sbConfig(key, initial = null) {
    try {
        return SB_DATA[key]
    } catch (e) {
        return initial
    }
}

export function aliasesJoin(arr, separator = null) {
    return Array.isArray(arr) ? arr.join(separator || sbConfig('aliases_separator')) : arr
}
