import { combineReducers } from 'redux';
import filterReducer from "./filterReducer";
import routeSearchReducer from "./routeSearchReducer";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";

const reducers = {
    auth:authReducer,
    filter:filterReducer,
    routeSearch:routeSearchReducer,
    order:orderReducer,

  // videoData:videoReducer,
  // subtitles:subtitlesReducer,
  // configMenu:configMenuReducer,
  // controlPlayer:controlPlayerReducer,
};

export default combineReducers(reducers);
