import React from 'react'

function Seat({seat, onSelect, takenPlaces = [], selected = []}) {

    //<i className="number">{s.number}</i>

    if (!seat) {
        return (
            <div className={`sead-item sead-item--type_empty`}></div>
        )
    }

    if (seat.driver) {
        return (
            <div className={`sead-item sead-item--type_dr`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 11.1719C10.3236 11.1719 10.5859 10.9095 10.5859 10.5859C10.5859 10.2623 10.3236 10 10 10C9.6764 10 9.41406 10.2623 9.41406 10.5859C9.41406 10.9095 9.6764 11.1719 10 11.1719Z"
                        fill="black"/>
                    <path
                        d="M10 0C4.49383 0 0 4.4941 0 10C0 15.5063 4.4941 20 10 20C15.5063 20 20 15.5059 20 10C20 4.49383 15.5059 0 10 0ZM10 2.38281C13.8017 2.38281 16.9617 5.18234 17.527 8.82812H15.495C14.2067 8.82812 12.9826 8.31113 12.1367 7.40973C10.9805 6.17746 9.01965 6.17707 7.86328 7.40969C7.01738 8.31113 5.79332 8.82812 4.50492 8.82812H2.47297C3.03832 5.18234 6.19832 2.38281 10 2.38281ZM10 12.3438C9.03078 12.3438 8.24219 11.5552 8.24219 10.5859C8.24219 9.61664 9.03078 8.82812 10 8.82812C10.9693 8.82812 11.7578 9.61664 11.7578 10.5859C11.7578 11.5552 10.9693 12.3438 10 12.3438ZM2.90703 12.7772C3.57773 12.5141 4.06203 12.3438 4.72656 12.3438C6.86281 12.3438 8.52418 14.2474 8.20238 16.3863H8.20102C8.1532 16.7137 8.05914 17.0309 7.92348 17.3295C5.6282 16.6783 3.77074 14.9755 2.90703 12.7772ZM12.077 17.3293C11.9376 17.0229 11.8456 16.7057 11.799 16.3863H11.7976C11.4227 13.8939 13.7234 11.8374 16.1083 12.4435C16.3597 12.5074 16.6743 12.613 17.0929 12.7773C16.2293 14.9754 14.372 16.6781 12.077 17.3293Z"
                        fill="black"/>
                </svg>
            </div>
        )
    }

    if (takenPlaces && takenPlaces.includes(seat.place)) {
        return (
            <div className={`sead-item sead-item--type_nu`}>
                <i className="number">{seat.place}</i>

                <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.9079 4.56653C27.9079 2.09121 26.1849 0 24.0615 0H3.84644C1.72301 0 0 2.07699 0 4.56653L1.21339 26.887H26.6946L27.9079 4.56653Z"
                        fill="black"/>
                    <path
                        d="M26.0804 32.1521V30.1229C26.0804 29.1126 25.3152 28.3096 24.411 28.3096H3.48969C2.58544 28.3096 1.82031 29.1126 1.82031 30.1229V32.1435C1.82804 32.9033 2.24538 33.5768 2.8714 33.8618C3.06462 33.9481 3.28102 33.9999 3.48969 33.9999H24.4187C24.6274 33.9999 24.8361 33.9481 25.0293 33.8618L25.037 33.8532C25.663 33.5768 26.0804 32.9033 26.0881 32.1521H26.0804Z"
                        fill="black"/>
                </svg>
            </div>
        )
    }

    return (
        <div
            className={`sead-item sead-item--type_seat ${selected.includes(seat) ? 'selected' : ""}`}
            onClick={onSelect.bind(null, seat)}
        >
            <i className="number">{seat.place}</i>

            <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.9079 4.56653C27.9079 2.09121 26.1849 0 24.0615 0H3.84644C1.72301 0 0 2.07699 0 4.56653L1.21339 26.887H26.6946L27.9079 4.56653Z"
                    fill="#CACACA"/>
                <path
                    d="M26.0804 32.1521V30.1229C26.0804 29.1126 25.3152 28.3096 24.411 28.3096H3.48969C2.58544 28.3096 1.82031 29.1126 1.82031 30.1229V32.1435C1.82804 32.9033 2.24538 33.5768 2.8714 33.8618C3.06462 33.9481 3.28102 33.9999 3.48969 33.9999H24.4187C24.6274 33.9999 24.8361 33.9481 25.0293 33.8618L25.037 33.8532C25.663 33.5768 26.0804 32.9033 26.0881 32.1521H26.0804Z"
                    fill="#8E8E8E"/>
            </svg>
        </div>
    )
}

export default Seat
