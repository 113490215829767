import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from "react-router-dom";

const Autologin = props => {
    const routeParams = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        if(routeParams.jwt){
            localStorage.setItem('token',routeParams.jwt)
        }
        setTimeout(()=>{
            // navigate('/search-results');
            navigate('/user-account');
        },300)
    },[])
    return (
        <div>Please wait verification</div>
    );
};

Autologin.propTypes = {

};

export default Autologin;
