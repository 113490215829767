// import Cryptr from 'cryptr';
const getHeaders = (obj) => {
  const headers = obj || {}
 // const token = w.auth.getToken();
  // if (token) {
  //   try {
  //     const cryptr = new Cryptr('myTotalySecretKey');
  //     const decryptedString = cryptr.decrypt(token);
  //     headers['X-Access-Token'] = decryptedString
  //   } catch (err) { console.log('Err::: ', err); return err }
  // }
  return headers;
}

export function header() {
  return getHeaders({ 'Content-Type': 'application/json' })
}
export function fileHeader() {
  return getHeaders({ 'Content-Type': 'multipart/form-data' })
}
