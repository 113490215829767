import React from 'react';
import validator from 'validator';
import moment from "moment";
import {sbConfig} from "../app";
import {useTranslation} from "react-i18next";
export function firstEngineError(data) {
    const ferror= data[ Object.keys(data)[ 0 ] ];
    if(Array.isArray(ferror)){
        if(ferror.length>0){
            return  ferror[ 0 ];
        }
    }
    if( typeof ferror === 'string'){
        return   ferror;
    }

    return false;
}

export function  isNotValidEmail(value){
    if (!validator.isEmail(value)) {
        return `${ value } is not a valid email.`
    }
    return  false
}
export function  isMinLengthString(value,length){
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return 'require';
    }
    if (value.toString().trim().length <length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return `The password must be at least ${ length } characters`;
    }
    return  false
}

export function  formatDate(value){
  return moment.utc(value).local().format('DD.MM.YYYY')
}
export function  formatTime(value){
  return moment.utc(value).local().format('HH:mm')
}

export function getTypePlaceByValue(type){
    const {t} = useTranslation();
   let list = sbConfig('ticket_types', null)
    // if(list[type])
        return t(list[type])
    // debugger
}

