import React, { useState, useEffect } from 'react';
//hook for works with form input delay
function useInputDebounce (value, delay){
  const [ debouncedValue, setDebouncedValue ] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {setDebouncedValue(value);}, delay);
      return () => {clearTimeout(handler);};
    },
    [ value ]
  );

  return debouncedValue;
}
export default useInputDebounce;
