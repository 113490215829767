import React, {Component} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class MainContent extends Component {
    render() {
        const settings = {
            // autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        return (
            <section className='main-content'>
                <img className={'main-logo'} src={'images/logo.svg'} alt="Logotype Superpilet" width='240' height='78'/>
                <div className="container">
                    <p>Точный график их движения вы всегда найдете у нас на сайте, а также сможете забронировать и
                        выкупить билеты на подходящий для вас рейс. Путешествия на автобусах — это выгодно и быстро:
                        билет стоит дешевле, чем на самолет, а до места назначения вы доберетесь гораздо быстрее, чем на
                        поезде.</p>
                </div>

                <article className={'available-trips'}>
                    <div className={'container'}>
                        <h3>Доступные поездки</h3>

                        <div className="trips-wrapper">
                            <div className={'trip'}>
                                <img src={'images/trip1.jpg'} alt="Tallin Narva" width={'930'} height={'350'}/>

                                <div className="text-wrapper">
                                    <p><strong>Таллинн — Нарва</strong></p>

                                    <p><strong>45 €</strong></p>

                                    <p className={'price'}><strong>7 €</strong></p>
                                </div>
                            </div>

                            <div className={'trip'}>
                                <img src={'trip2.jpg'} alt="Tallin Kuressaare" width={'450'} height={'350'}/>

                                <div className="text-wrapper">
                                    <p><strong>Таллинн — Курессааре</strong></p>

                                    <p><strong>45 €</strong></p>

                                    <p className={'price'}><strong>7 €</strong></p>
                                </div>
                            </div>

                            <div className={'trip'}>
                                <img src={'images/trip3.jpg'} alt="Tallin Kuressaare" width={'450'} height={'350'}/>

                                <div className="text-wrapper">
                                    <p><strong>Таллинн — Курессааре</strong></p>

                                    <p><strong>45 €</strong></p>

                                    <p className={'price'}><strong>7 €</strong></p>
                                </div>
                            </div>

                            <div className={'trip'}>
                                <img src={'images/trip4.jpg'} alt="Tallin Pyarnu" width={'450'} height={'350'}/>
                                <div className="text-wrapper">
                                    <p><strong>Таллинн — Пярну</strong></p>

                                    <p><strong>45 €</strong></p>

                                    <p className={'price'}><strong>7 €</strong></p>
                                </div>
                            </div>

                            <div className={'trip'}>
                                <img src={'images/trip5.jpg'} alt="Tallin Pyarnu" width={'450'} height={'350'}/>

                                <div className="text-wrapper">
                                    <p><strong>Таллинн — Пярну</strong></p>

                                    <p><strong>45 €</strong></p>

                                    <p className={'price'}><strong>7 €</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className={'news'}>
                    <div className={'container'}>
                        <h3>Новости</h3>

                        <div className={'slick-wrapper'}>
                            <Slider {...settings}>
                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick1.jpg'} alt="News 1 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>

                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick2.jpg'} alt="News 2 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>

                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick3.jpg'} alt="News 3 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>

                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick1.jpg'} alt="News 4 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>

                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick2.jpg'} alt="News 5 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>

                                <div className={'item-wrapper'}>
                                    <div className={'inner-wrapper'}>
                                        <div className="image-wrapper">
                                            <img src={'images/slick3.jpg'} alt="News 6 image" width={'451'}
                                                 height={'257'}/>
                                        </div>

                                        <h4>Открытие inBus Point.</h4>

                                        <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной
                                            автостанции Харькова. Для улучшения...</p>
                                        <a className={'news-button flex'}><strong>ПОДРОБНЕЕ</strong></a>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </article>
            </section>
        )
    }
}

export default MainContent;
