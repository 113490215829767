import React, {useState, useEffect} from "react";
import TopMenu from "../../components/TopMenu";
import BreadCrumbs from "../../components/Ui/BreadCrumbs/BreadCrumbs";
import Footer from "../../components/Footer";
import {useTranslation} from "react-i18next";

const NewsItem = () => {
    const {t, i18n, ready} = useTranslation();
    const breadcrumbsData = [
        {text: t('Главная'), href: '/'},
        {text: t('Новости'), href: false}
    ]
    return (
        <div className='page page-content'>
            <TopMenu/>

            <div className='custom-tab--head'>
                <div className="page-container">
                    <BreadCrumbs data={breadcrumbsData}/>
                </div>
            </div>

            <div className="page-container">
                <div className="news-article-view">
                    <div className="news-thumbnail">
                        <img src="/images/news-article.png" alt="news-title"/>
                    </div>

                    <div className="post-date">Август 3, 2021</div>

                    <h1 className="news-article-title">Открытие inBus Point. Первая кофейная заправка на
                        автостанции</h1>

                    <p>На прошлой неделе начала работать первая кофейня InBus Point на Центральной автостанции Харькова.
                        Для улучшения пассажирского сервиса планируется открытие таких поинтов на каждой
                        автостанции.</p>

                    <p>Концепция заведения была разработана по образцу современного клиентского сервиса на
                        автозаправках. Кофе готовится с помощью автоматической кофемашины Franke со встроенным
                        кофемолкой, что позволяет быстро готовить широкий ассортимент напитков со свежемолотого
                        кофе.</p>

                    <p>Все клиенты сервиса продажи автобусных билетов онлайн InBus получают все позиции меню по специальным
                        ценам -10%.</p>

                    <p>InBus Point также оборудован зарядными станциями для телефонов, которыми можно воспользоваться всем
                        посетителям автостанции.</p>

                    <p>Нашей целью является создание безопасного пространства с высоким сервисом.</p>
                </div>

                <section className={'rules rules-default-page'}>
                    <section className={'container '}>
                        <div className={'subscribe-link-block'}>
                            <p className={'subscribe-caption'}><strong>Мы предлагаем бесплатные советы о выгодных
                                путешествиях</strong></p>
                            <p className={'subscribe-text'}>Подпишитесь на рассылку, чтобы первым получать интересные
                                предложения, новости и скидки от Superpilet!</p>
                            <div className={'subscribe-form'}>
                                <input className={'subscribe-input'} type="email" placeholder="Email Address"/>
                                <button className='button-link orange'>Подписаться</button>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

            <Footer/>
        </div>
    )
}
export default NewsItem
