import React, {useEffect, useState} from 'react'
import {ValidatorForm} from "react-material-ui-form-validator";
import bell from '../../bell'
import Loading from "../Loading";
import ContactDataForm from "../Ui/ContactDataForm";
import {deleteData, getData, postDataObject} from "../../api/baseAPI";
import {useCartContext} from "./CartProvider";
import {useTranslation} from "react-i18next";

function CartForm({onSuccess}) {

    const cart = useCartContext()
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [tickets, setTickets] = useState([])
    const [seats, setSeats] = useState([])
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true)
        getData(`/cart-tickets`)
            .then(({data}) => {
                setTickets(data.tickets || [])
            })
            .catch(({message}) => console.log(message))
            .then(setLoading.bind(null, false))
    }, [])

    function handleDeleteTicket(ticket, e) {
        e && e.preventDefault()

        setProcessing(true)
        deleteData(`/cart-ticket/${ticket.id}`)
            .then(({data}) => {
                cart.setInfo(data.info || {})

                const _tickets = tickets.filter(t => t !== ticket)
                if (!_tickets.length) {
                    cart.setOpen(false)
                } else {
                    setTickets(_tickets)
                }
                bell.hit('cart-delete-ticket', ticket)
            })
            .catch(({message}) => console.log(message))
            .then(setProcessing.bind(null, false))
    }

    function handleChangeTicket(ticket, name, value) {
        setTickets(tickets.map(t => {
            if (t === ticket) {
                const temp = {...ticket, [name]: value}
                if (['ticket_type', 'sms'].includes(name)) {
                    updateTicketPrice(temp)
                }
                return temp
            }
            return t
        }))
    }

    function updateTicketPrice(ticket) {

        const stationFrom = ticket.station_from
        const stationTo = ticket.station_to

        setProcessing(true)
        getData(`/bus-trips/ticket-price`, {
            tripId: ticket.trip.id,
            stationFromId: stationFrom.id,
            stationToId: stationTo.id,
            place: ticket.place,
            ticketKeyType: ticket.ticket_type,
            sms: ticket.sms ? '1' : '0',
        })
            .then(({data}) => {
                if (data.price) {
                    setTickets(tickets.map(t => {
                        if (t.id === ticket.id) {
                            return {...ticket, price: data.price}
                        }
                        return t
                    }))
                }
            })
            .catch(({message}) => console.log(message))
            .then(setProcessing.bind(null, false))
    }

    function handleSubmit() {
        setProcessing(true)
        postDataObject('/cart-tickets/add-details', {tickets})
            .then(({data}) => {
                if (data.warning) {
                    alert(data.warning)
                } else {
                    bell.hit('cart-tickets-ready')
                    onSuccess && onSuccess()
                }
            })
            .catch(({message}) => console.log(message))
            .then(setProcessing.bind(null, false))
    }

    if (loading) {
        return <Loading/>
    }

    return (
        <ValidatorForm
            // key={seats.map(s => s.place)}
            onSubmit={handleSubmit}
        >
            <div className="ticket-registration--dialog__tabs-list">
                {tickets.map(ticket =>
                    <ContactDataForm
                        key={ticket.id}
                        ticket={ticket}
                        onChangeTicket={handleChangeTicket}
                        onClickDelete={handleDeleteTicket.bind(null, ticket)}
                        processing={processing}
                    />
                )}
            </div>

            <div className="ticket-registration--dialog__tabs-action">
                <button
                    className="base-orange-btn"
                    disabled={processing}
                >{t('checkout')}
                </button>
            </div>
        </ValidatorForm>

    )
}

export default CartForm
