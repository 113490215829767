import React, { Component } from 'react';
import Filter from './filter/Filter';
import Switch from 'react-switch';
import Slider from 'react-custom-slider';

import { t } from 'i18next';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
class MainBG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            switchedFilter: false,
            value: 0,
            sliderWidth: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        // const [ value, setValue ] = React.useState(0);


    }

    handleChange(checked) {
        this.setState({ checked });
    }

    toggleFilter(switchedFilter) {
        this.setState(({ switchedFilter }) => ({ switchedFilter: !switchedFilter }))
        setTimeout(()=>{
            this.updateSliderWidth()
        })

    }

    componentDidMount() {
        window.addEventListener('resize', this.updateSliderWidth);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateSliderWidth);
    }

    updateSliderWidth = () => {
        if(document.getElementsByClassName('price-slider-wrapper')[0].clientWidth){
            this.setState({
                sliderWidth: document.getElementsByClassName('price-slider-wrapper')[0].clientWidth
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <section className='main-bg'>
                    <div className='buttons-block'>
                        <Link  to="/search-results" className='button-link orange'>{t('Search tickets')}</Link>
                        <a href='#' className='button-link orange'>{t('Schedule')}</a>
                    </div>
                    <section className='container form'>
                        <Filter/>
                        {/*<section className='search-results'>*/}
                        {/*    <ListOfSearchRoutes/>*/}
                        {/*</section>*/}
                        <section className={ 'switch-block' }>
                            <label className={ 'switch-wrapper' } htmlFor="material-switch">
                                <Switch
                                    checked={ this.state.checked }
                                    onChange={ this.handleChange }
                                    offColor="#DDDDDD"
                                    offHandleColor="#BDBDBD"
                                    onColor="#f3bd99"
                                    onHandleColor="#E97D35"
                                    handleDiameter={ 20 }
                                    uncheckedIcon={ false }
                                    checkedIcon={ false }
                                    boxShadow="0px 0px 0px rgba(0, 0, 0, 0)"
                                    activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                                    height={ 14 }
                                    width={ 34 }
                                />
                                <p>Туда/обратно</p>
                            </label>
                        </section>
                        <section className={ 'filter-block' }>
                            <div className={ `filter-switcher-wrapper ${ this.state.switchedFilter ? 'active' : '' }` } onClick={ this.toggleFilter }>
                                <p className={ 'filter-switcher-text first-state' }>Фильтр</p>
                                <p className={ 'filter-switcher-text finish-state' }>Скрыть фильтр</p>
                                <img className={ 'icon-filter-switcher first-state' } src={ 'images/filter-switcher-icon.svg' }
                                     alt="Filter switcher icon" width={ '15' }
                                     height={ '9' }/>
                                <img className={ 'icon-filter-switcher finish-state' } src={ 'images/filter-switcher-active-icon.svg' }
                                     alt="Filter switcher icon" width={ '15' }
                                     height={ '9' }/>
                            </div>
                            <div className={ `price-slider-wrapper ${ this.state.switchedFilter ? 'active' : '' }` }>
                                <p className={ 'price-text' }><strong>Цена</strong></p>

                                <Slider
                                    value={ this.state.value }
                                    showValue={ true }
                                    valueLabelColor={ '#282828' }
                                    valueLabelActiveColor={ '#282828' }
                                    min={ 0 }
                                    max={ 500 }
                                    stepSize={ 1 }
                                    trackLength={this.state.sliderWidth - 110}
                                    trackThickness={ 2 }
                                    trackShape={ 'rounded' }
                                    trackColor={ '#acacac' }
                                    fillTrack={ true }
                                    fillColor={ '#E97D35' }
                                    //handlerSize={ 18 }
                                    handlerShape={ 'rounded' }
                                    handlerColor={ '#E97D35' }
                                    handlerBorderColor={ '#E97D35' }
                                    handlerActiveColor={ '#E97D35' }
                                    showMarkers={ true }
                                    onChange={ (value) => this.setState({ value: value }) }
                                    valueRenderer={ () => `${ this.state.value } €` }
                                />
                            </div>
                        </section>
                    </section>
                </section>

            </React.Fragment>
        )
    }
}

export default MainBG;
